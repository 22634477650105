import React, { useState } from 'react';
import './SummaryButton.css';
import SummaryFormComponent from '../SummaryFormComponent/SummaryFormComponent';
import { useConfiguratorContext } from '../../../ContextProvider'; // Updated import path and hook name

const SummaryButton = ({ id = 'default-summary-button-id' }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { closeAll } = useConfiguratorContext();

  const handleOpenPopup = () => {
    if (closeAll) {
      closeAll();
    }
    setIsPopupOpen(true);
  };

  return (
    <>
      <button id={id} className="summary-button" onClick={handleOpenPopup}>
        <span className="summary-text">Podsumowanie</span>
      </button>
      {isPopupOpen && <SummaryFormComponent onClose={() => setIsPopupOpen(false)} />}
    </>
  );
};

export default SummaryButton;
