// CustomerData.jsx
import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    padding: 2,
    backgroundColor: "#f9f9f9",
    border: "1px solid #e0e0e0",
  },
  heading: {
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 2,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: 1,
  },
  tableRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  tableHeader: {
    padding: 3,
    border: "1px solid #d0d0d0",
    backgroundColor: "#e0e0e0",
    fontWeight: "bold",
    width: "50%",
    fontSize: 7,
  },
  tableCell: {
    padding: 3,
    border: "1px solid #d0d0d0",
    width: "50%",
    fontSize: 7,
  },
});

const CustomerData = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.heading}>Dane Klienta</Text>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Imię i nazwisko</Text>
        <Text style={styles.tableCell}>{data.fullName}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>E-mail</Text>
        <Text style={styles.tableCell}>{data.userEmail}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Telefon</Text>
        <Text style={styles.tableCell}>{data.phone}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Kod pocztowy</Text>
        <Text style={styles.tableCell}>{data.postalCode}</Text>
      </View>
    </View>
  </View>
);

export default CustomerData;
