import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedColorId } from "../../store/reducers/colorChoiceSlice";
import ColorChoiceRhombus from "./ColorChoiceRhombus";
import "./ColorChoiceGrid.css";

const ColorChoiceGrid = ({ ids, colors, names, category }) => {
  const dispatch = useDispatch();
  const selectedId = useSelector((state) => state.colorChoice[category]);

  const handleSelect = (id) => {
    dispatch(setSelectedColorId({ category, id }));
  };

  return (
    <div className="flex flex-wrap">
      <div className="colorgrid">
        {ids.map((id, index) => (
          <div key={id} onClick={() => handleSelect(id)}>
            <ColorChoiceRhombus
              id={id}
              color={colors[index]}
              name={names[index]}
              selected={selectedId === id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorChoiceGrid;
