import React from "react";
import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";

// Styles for Contact Information
const styles = StyleSheet.create({
  contactTextContainer: {
    alignSelf: "flex-start",
  },
  contactText: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#000000",
  },
  infoFlexRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  columnLeft: {
    flexDirection: "column",
    marginTop: 4,
  },
  leftTitle: {
    fontSize: 7.9,
    fontWeight: "bold",
    color: "#000000",
  },
  leftSubtitle: {
    fontSize: 7.9,
    fontWeight: "normal",
    color: "#000000",
  },
  rowRight: {
    flexDirection: "row",
    marginLeft: 29.5,
    marginTop: 10,
  },
  contactItem: {
    fontSize: 7.9,
    fontWeight: "normal",
    color: "#000000",
    marginRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 15, // Adjust size as needed
    height: 15, // Adjust size as needed
    marginRight: 5, // Space between icon and text
  },
});

const ContactInfo = () => (
  <>
    {/* Contact Information Text */}
    <View style={styles.contactTextContainer}>
      <Text style={styles.contactText}>Dane kontaktowe:</Text>
    </View>

    {/* Information Flex Row */}
    <View style={styles.infoFlexRow}>
      {/* Left Column */}
      <View style={styles.columnLeft}>
        <Text style={styles.leftTitle}>MARCINIAK OGRODZENIA</Text>
        <Text style={styles.leftSubtitle}>Lubuska 47, 66-016 Płoty</Text>
      </View>

      {/* Right Column */}
      <View style={styles.rowRight}>
        <View style={styles.contactItem}>
          <Image src="/phoneicon.png" style={styles.icon} />
          <Text>+48 732 081 990</Text>
        </View>
        <View style={styles.contactItem}>
          <Image src="/koperta.png" style={styles.icon} />
          <Text>kontakt@marciniakogrodzenia.pl</Text>
        </View>
        <View style={styles.contactItem}>
          <Image src="/globe.png" style={styles.icon} />
          <Text>www.marciniakogrodzenia.pl</Text>
        </View>
      </View>
    </View>
  </>
);

export default ContactInfo;
