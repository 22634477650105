const getModelTypeText = (selectedModelId) => {
  switch (selectedModelId) {
    case '1.a':
      return 'Jednostanowiskowa';
    case '1.b':
      return 'Dwustanowiskowa';
    case '1.c':
      return 'Wielostanowiskowa';
    case '1.d':
      return 'Dwustanowiskowa';
    case '1.e':
      return 'Dwustanowiskowa';
    case '1.f':
      return 'Dwustanowiskowa';
  }
};

export default getModelTypeText;
