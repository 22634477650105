import React, { useState, useEffect, useCallback, useRef} from "react";
import Slider from "./Slider";
import "./SliderContainer.css";
import useInfoWindow from "../../../hooks/useInfoWindow";
import { infoData } from "../../../utils/infoData";

const SliderContainer = ({
  id,
  min,
  max,
  realMin,
  realMax,
  dimension,
  onDimensionChange,
  blurActiveInput, // Receiving blur function from parent
  focusedInputRef,  // Receiving the ref for the active input
  selectedId,
  showInfoIcon,
  customDimensionText,
  pgDimensionInfo,
}) => {
  const [value, setValue] = useState(min);
  const [inputValue, setInputValue] = useState(min.toString());
  const [displayValue, setDisplayValue] = useState(min.toString());
  const [rangeWarning, setRangeWarning] = useState("");
  const [isInputActive, setIsInputActive] = useState(false);
  const [customText, setCustomText] = useState("");
  const [pgInfoText, setPgInfoText] = useState("");
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow(id);

  const fakeInputRef = useRef(null);

  const dispatchSliderEvent = useCallback(
    (newValue) => {
      const sliderElem = document.getElementById(id);
      if (sliderElem) {
        sliderElem.value = newValue;
        sliderElem.dispatchEvent(new Event("input"));
      }
    },
    [id]
  );

  const mapValueToRealRange = useCallback(
    (sliderValue) => {
      return ((realMax - realMin) * sliderValue + realMin).toFixed(3);
    },
    [realMin, realMax]
  );

  const mapRealRangeToValue = useCallback(
    (realValue) => {
      return ((realValue - realMin) / (realMax - realMin)).toFixed(3);
    },
    [realMin, realMax]
  );

  const checkRange = useCallback(
    (val) => {
      if (val < realMin) {
        setRangeWarning(`Minimalna ${dimension} dla wiaty to ${realMin}`);
      } else if (val > realMax) {
        setRangeWarning(`Maksymalna ${dimension} dla wiaty to ${realMax}`);
      } else {
        setRangeWarning("");
      }
    },
    [realMin, realMax, dimension]
  );

  useEffect(() => {
    setValue(min);
    setInputValue(min.toString());
    setDisplayValue(realMin.toString());
    setRangeWarning("");
    onDimensionChange(min.toString());
  }, [selectedId]);

  useEffect(() => {
    dispatchSliderEvent(min);
  }, [selectedId, dispatchSliderEvent, min]);

  useEffect(() => {
    setInputValue(mapValueToRealRange(value));
    setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    checkRange(parseFloat(mapValueToRealRange(value)));
  }, [value, mapValueToRealRange, checkRange]);

  const handleSliderChange = (e) => {
    const newValue = e.target.valueAsNumber;
    blurActiveInput();  // Trigger the blur of any active input
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
      onDimensionChange(mapValueToRealRange(newValue));
    }
  };

  const handleNumericInputChange = (e) => {
    const newRealValue = parseFloat(e.target.value);
    setInputValue(e.target.value);
    checkRange(newRealValue);

    if (
      !isNaN(newRealValue) &&
      newRealValue >= realMin &&
      newRealValue <= realMax
    ) {
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(e.target.value);
      dispatchSliderEvent(mapRealRangeToValue(newRealValue));
    }
  };

  const handleNumericInputBlur = () => {
    const newRealValue = parseFloat(inputValue);
    if (
      !isNaN(newRealValue) &&
      newRealValue >= realMin &&
      newRealValue <= realMax
    ) {
      // If a valid number is entered, continue as normal
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(mapValueToRealRange(newRealValue));
      setDisplayValue(mapValueToRealRange(newRealValue).split(".")[0] + " cm");
    } else {
      // If no valid number is entered or field is left empty, reset to the original displayed value
      setInputValue(mapValueToRealRange(value));
      setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    }
  };

  // This function is used to handle the input display behavior
  const handleFakeInputBlur = useCallback(() => {
    const newRealValue = parseFloat(displayValue);
    if (!isNaN(newRealValue) && newRealValue >= realMin && newRealValue <= realMax) {
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(mapValueToRealRange(newRealValue));
      setDisplayValue(newRealValue.toString() + " cm");
    } else {
      setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    }
    setIsInputActive(false); // Set input as inactive
    fakeInputRef.current?.blur(); // Programmatically remove focus
  }, [displayValue, mapRealRangeToValue, mapValueToRealRange, realMax, realMin, value, onDimensionChange]);

  const handleClickOutside = useCallback((event) => {
    // Check if the click is outside the fake input or any other slider
    if (fakeInputRef.current && !fakeInputRef.current.contains(event.target)) {
      if (isInputActive) {
        handleFakeInputBlur(); // Trigger blur only if input is active
      }
    }
  }, [handleFakeInputBlur, isInputActive]);

  useEffect(() => {
    // Add event listener to detect clicks outside the fake input field
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleNumericInputFocus = () => {
    setDisplayValue("");
    setIsInputActive(true);
    focusedInputRef.current = fakeInputRef.current; // Set the current input as focused
  };

  const handleFakeInputChange = (e) => {
    const numericValue = parseFloat(e.target.value);
    const newDisplayValue = isNaN(numericValue) ? "" : numericValue.toString();
    setDisplayValue(newDisplayValue + " cm");
    checkRange(numericValue);

    if (!isNaN(numericValue) && numericValue >= realMin && numericValue <= realMax) {
      setValue(mapRealRangeToValue(numericValue));
      onDimensionChange(numericValue.toString());
      dispatchSliderEvent(mapRealRangeToValue(numericValue));
    }
  };

  

  useEffect(() => {
    const realValue = parseFloat(mapValueToRealRange(value));
    setDisplayValue(`${Math.round(realValue)} cm`);
    checkRange(realValue);

    if (customDimensionText) {
      const customText = customDimensionText(Math.round(realValue));
      // Make the first part bold
      const parts = customText.split(": ");
      setCustomText(
        <>
          <span className="bold-text">{parts[0]}:</span> {parts[1]}
        </>
      );
    } else {
      setCustomText("");
    }
  }, [value, mapValueToRealRange, checkRange, customDimensionText]);

  useEffect(() => {
    if (pgDimensionInfo) {
      const realValue = parseFloat(mapValueToRealRange(value));
      const pgText = pgDimensionInfo(Math.round(realValue));
      // Make the first part bold
      const parts = pgText.split(": ");
      setPgInfoText(
        <>
          <span className="bold-text">{parts[0]}:</span> {parts[1]}
        </>
      );
    } else {
      setPgInfoText("");
    }
  }, [value, mapValueToRealRange, pgDimensionInfo]);


  return (
    <div className="slider-container">
      <div className="slider-row">
        <input
          className="dimension-input"
          type="text"
          value={dimension}
          readOnly
        />
        <div className="input-container">
          <input
            type="text"
            value={displayValue.split(" ")[0]}
            className="fake-input"
            onFocus={handleNumericInputFocus}
            onChange={handleFakeInputChange}
            onBlur={handleFakeInputBlur}
            ref={fakeInputRef}
          />
          <div className="unit">cm</div>
        </div>
      </div>
      <div className="dimension-display">
        {rangeWarning && <p className="range-warning">{rangeWarning}</p>}
        {showInfoIcon && (
          <div
            className="info-icon2"
            onClick={(e) => {
              e.stopPropagation();
              toggleInfoWindow(id);
            }}
            title="More information"
          >
            i
          </div>
        )}
      </div>
      <div>
        <div>
          <input
            type="text"
            value={inputValue}
            onChange={handleNumericInputChange}
            onBlur={handleNumericInputBlur}
            style={{ display: "none" }}
          />
          <div className="input-wrapper">
            <div className="inner-wrapper"></div>
            <Slider
              id={id}
              min={min}
              max={max}
              value={value}
              onChange={handleSliderChange}
            />
          </div>
        </div>
      </div>
      <div className="custom-text-wrapper">
        {customText && (
          <div className="custom-dimension-text">{customText}</div>
        )}
        {pgInfoText && (
          <div className="custom-dimension-text">{pgInfoText}</div>
        )}
      </div>
      {renderInfoWindow()}
    </div>
  );
};

export default SliderContainer;
