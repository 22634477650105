import { useSelector } from "react-redux";
import { selectSelectedId as selectSelectedModelId } from "../../../store/reducers/modelChoiceSlice";
import {
  selectEmail,
  selectFullName,
  selectPhone,
  selectPostalCode,
  selectFeedbackMessage,
} from "../../../store/reducers/formInfoSlice";
import useGenerateOrderNumber from "../PDFGenerationUtils/choiceTranslationUtils/generateOrderNumber";
import getModelTypeText from "../PDFGenerationUtils/choiceTranslationUtils/modelTexts";
import { selectShedOptionsState } from "../../../store/reducers/shedOptionsSlice";
import {
  translateIsGarageChecked,
  translateIsRoomChecked,
  translateSelectedGarageOption,
  translateSelectedRoomOption,
  translateIsShedPositionChecked,
  translateisPhotovoltaicsReinforcementChecked,
  translateShedPosition,
  translateIsSnowZoneChecked,
  translateIsMontageChecked,
  translateIsGrillTypeChecked,
  translateIsWallTypeChecked,
  translateIsGutterTypeChecked,
} from "../PDFGenerationUtils/choiceTranslationUtils/translateShedOptions";
import { selectColorChoices } from "../../../store/reducers/colorChoiceSlice";
import { translateColorCode } from "../PDFGenerationUtils/choiceTranslationUtils/translateColorCode";
import { selectSelectedId as isSelectedPanelColorId } from "../../../store/reducers/panelColorChoiceSlice";
import { translatePanelColorId } from "../PDFGenerationUtils/choiceTranslationUtils/panelColorTranslations";
import { selectSelectedId as selectSelectedPanelId } from "../../../store/reducers/panelChoiceSlice";
import { translatePanelId } from "../PDFGenerationUtils/choiceTranslationUtils/panelIdTranslations";
import { selectSelectedSquares } from "../../../store/reducers/panelPlacementSlice";
import { selectDimensionsBySelectedId } from "../../../store/reducers/dimensionsSlice";
import { calculateSelectedSquares } from "./squaresCalculation";
import { useFormattedDimensions } from "./dimensionsUtils";
import { selectFinalPrice } from "../../../store/reducers/finalPriceSlice";

const useSetTemplateParams = () => {
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const generateOrderNumber = useGenerateOrderNumber();
  const orderNumber = generateOrderNumber();
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage = useSelector(selectFeedbackMessage);
  const selectedModelId = useSelector(selectSelectedModelId);
  let modelTypeText = getModelTypeText(selectedModelId);
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );

  const {
    formattedWidth,
    formattedLength,
    formattedHeight,
    formattedRoofDimension,
    pgDimensionInformation,
  } = useFormattedDimensions();

  const shedOptions = useSelector(selectShedOptionsState);
  const garageChecked = translateIsGarageChecked(shedOptions.isGarageChecked);
  const garageOption = translateSelectedGarageOption(
    shedOptions.selectedGarageOption
  );
  const shedChecked = translateIsRoomChecked(shedOptions.isRoomChecked);
  const shedOption = translateSelectedRoomOption(
    shedOptions.selectedRoomOption
  );
  const shedPositionChecked = translateIsShedPositionChecked(
    shedOptions.isShedPositionChecked
  );
  const montageChecked = translateIsMontageChecked(
    shedOptions.isMontageChecked
  );
  const shedPosition = translateShedPosition(shedOptions.shedPosition);
  const snowZoneChecked = translateIsSnowZoneChecked(
    shedOptions.isSnowZoneChecked
  );
  const photovoltaicsReinforcementChecked =
    translateisPhotovoltaicsReinforcementChecked(
      shedOptions.isPhotovoltaicsReinforcementChecked
    );
  const maskownicaPremiumChecked = translateIsGrillTypeChecked(
    shedOptions.isGrilleTypeChecked
  );

  const wallTypeChecked = translateIsWallTypeChecked(
    shedOptions.isWallTypeChecked,
    shedOptions.isGarageChecked,
    shedOptions.isRoomChecked
  );

  const rynnaChecked = translateIsGutterTypeChecked(
    shedOptions.isGutterTypeChecked
  );

  const colorChoices = useSelector(selectColorChoices);
  const poleColorName = translateColorCode(colorChoices.poleColor);
  const roofColorName = translateColorCode(colorChoices.roofColor);
  const facadeColorName = translateColorCode(colorChoices.facadeColor);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const panelColorName = translatePanelColorId(selectedPanelColorId);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const translatedPanelId = translatePanelId(selectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  let countOfSelectedSquares = calculateSelectedSquares(
    selectedSquares,
    selectedModelId,
    dimensions,
    shedOptions
  );

  const finalNettoPrice = useSelector(selectFinalPrice);
  const finalNettoPriceBeforeDiscount = finalNettoPrice * 0.9;

  const setTemplateParams = (imageString, pdfBase64) => ({
    screenshotImage: imageString,
    fullName: fullName,
    phone: phone,
    contactEmail: email,
    userEmail: email,
    orderNumber: orderNumber,
    message: "This is a custom message you might want to include.",
    reply_to: "konfigurator@marciniakogrodzenia.pl",
    modelType: modelTypeText,
    width: formattedWidth,
    length: formattedLength,
    height: formattedHeight,
    roofDimension: formattedRoofDimension,
    garageChecked: garageChecked,
    garageOption: garageOption,
    shedChecked: shedChecked,
    shedOption: shedOption,
    shedPositionChecked: shedPositionChecked,
    shedPosition: shedPosition,
    montageChecked: montageChecked,
    snowZoneChecked: snowZoneChecked,
    photovoltaicsReinforcementChecked: photovoltaicsReinforcementChecked,
    maskownicaPremiumChecked: maskownicaPremiumChecked,
    wallTypeChecked: wallTypeChecked,
    rynnaChecked: rynnaChecked,
    poleColorName: poleColorName,
    roofColorName: roofColorName,
    facadeColorName: facadeColorName,
    panelColorName: panelColorName,
    translatedPanelId: translatedPanelId,
    countOfSelectedSquares: countOfSelectedSquares,
    feedbackMessage: feedbackMessage,
    postalCode: postalCode,
    pgDimensionInformation: pgDimensionInformation,
    finalNettoPrice: finalNettoPrice,
    finalNettoPriceBeforeDiscount: finalNettoPriceBeforeDiscount,
    pdfAttachment: pdfBase64,
  });

  return setTemplateParams;
};

export default useSetTemplateParams;