// gutterPriceUtils.js
export const calculateGutterPrice = (dimensionsData, isGutterTypeChecked) => {
    if (!isGutterTypeChecked) {
      return 0;
    }
  
    const gutter = dimensionsData.find(
      (d) => d["Rozmiar (szer. x dł.)"] === "Rynna Kwadratowa"
    );
    const gutterPrice = gutter
      ? gutter["Cena Netto (zł) zaciagana do konfiguratora"]
      : 500;
  
    //console.log(`koszt rynny kwadratowej: ${gutterPrice}`);
    return gutterPrice;
  };
  