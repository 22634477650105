export const translateColorCode = (colorCode) => {
  const colorMap = {
    // Pole and Facade Colors
    '4.1.a': 'Antracyt', '4.3.a': 'Antracyt',
    '4.1.b': 'Ciemny brąz', '4.3.b': 'Ciemny brąz',
    '4.1.c': 'Jasny brąz', '4.3.c': 'Jasny brąz',
    '4.1.d': 'Srebrny', '4.3.d': 'Srebrny',
    '4.1.e': 'Czarny', '4.3.e': 'Czarny',
    '4.1.f': 'Biały', '4.3.f': 'Biały',
    // Roof Colors
    '4.2.a': 'Antracyt',
    '4.2.b': 'Ciemny brąz',
    '4.2.c': 'Srebrny',
    '4.2.d': 'Czarny',
  };

  return colorMap[colorCode] || 'Unknown Color';
};
