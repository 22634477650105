import React, { useState } from 'react';
import './MobileRedirectButton.css';
import HomeIcon from './Home1.png';  // Use a single icon here
import RedirectHomePopup from '../../miscellaneousComponents/RedirectHomePopup/RedirectHomePopup';

const MobileRedirectButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleRedirect = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <button
        className="mobile-redirect-button"
        onClick={handleRedirect}
      >
        <img src={HomeIcon} alt="Home" className="mobile-redirect-icon" />
      </button>
      {isPopupOpen && <RedirectHomePopup onClose={handleClosePopup} />}
    </>
  );
};

export default MobileRedirectButton;
