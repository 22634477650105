const panelIdToName = {
  '5.a': 'Falista',
  '5.b': 'Horyzontal',
  '5.c': 'Modern Plus',
  '5.d': 'Żaluzja',
};

export const translatePanelId = (panelId) => {
  return panelIdToName[panelId] || panelId;
};
