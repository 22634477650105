import React from "react";
import "./PanelColorChoiceRhombus.css";

const PanelColorChoiceRhombus = ({ id, color, selected, isVisible, name }) => {
  const [boldPart, regularPart] = name.split(" (");
  const regularText = regularPart ? `(${regularPart}` : "";

  const textColorClass = name.includes("Biały") ? "black-text" : "white-text";

  return (
    <div
      className={`panel-color-choice-rhombus-wrapper ${
        selected ? "selected" : ""
      } ${isVisible ? "" : "hidden"}`}
      id={id}
    >
      <div className="panel-color-choice-rhombus">
        <div className="color-block" style={{ backgroundColor: color }}></div>
        <div className={`hover-text ${textColorClass}`}>
          <span className="bold">{boldPart}</span>
          <span className="regular"> {regularText}</span>
        </div>
      </div>
    </div>
  );
};

export default PanelColorChoiceRhombus;
