import React from "react";
import "./RedirectHomePopup.css";
import CloseRedirectHomeButton from "./CloseRedirectHomeButton";
import StayButton from "./StayButton";
import LeaveButton from "./LeaveButton";

const RedirectHomePopup = ({ onClose }) => {
  const handleRedirect = () => {
    window.location.href = "https://marciniakogrodzenia.pl/";
  };
  return (
    <div className="rdhpop">
      <div className="RedirectHomePopup-overlay">
        <div className="RedirectHomePopup-content">
          <div className="RedirectHomePopup-finalization-summary-container">
            <CloseRedirectHomeButton onClick={onClose} />
            <div className="flex flex-col space-y-4">
              <div className="text-center font-bold text-black">
                Czy na pewno chcesz wrócić do strony głównej?
              </div>
              <div className="text-center text-black">
                Wszystkie wprowadzone zmiany zostaną utracone.
                <br />
                Nie będzie możliwości ich uzyskania.
              </div>
              <div className="flex flex-row justify-center space-x-4">
                <StayButton onClick={onClose} />
                <LeaveButton onClick={handleRedirect} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectHomePopup;
