export const infoData = {
  "1.a": {
    text: "Zapewnij swojemu pojazdowi doskonałą ochronę z wiatą jednostanowiskową, wytworzoną z myślą o trwałości i estetyce. Konstrukcja, wykonana z ocynkowanej ogniowo i malowanej proszkowo stali, gwarantuje długotrwałe użytkowanie bez potrzeby regularnej konserwacji. Ta elegancka wiata zapewnia kompleksową ochronę, skutecznie osłaniając Twój samochód przed szkodliwymi promieniami UV, opadami deszczu oraz silnymi podmuchami wiatru, utrzymując go w idealnym stanie przez cały rok."
    ,
    image: "pojedyncza.jpg",
    title: "Wiata jednostanowiskowa",
  },
  "1.b": {
    text: "Wiata dwustanowiskowa to synonim ochrony i przestrzeni. Dzięki solidnej konstrukcji z najlepszych materiałów, zapewnia bezpieczny azyl dla dwóch pojazdów przed niekorzystnymi warunkami atmosferycznymi. Eleganckie wykończenie i nowoczesny design sprawiają, że wiata jest nie tylko praktycznym, ale i estetycznym dodatkiem do każdej posesji. Dodatkowa przestrzeń może służyć również jako miejsce do przechowywania rowerów, narzędzi ogrodowych czy sezonowego sprzętu.",
    image: "podwojna.jpg",
    title: "Wiata dwustanowiskowa",
  },
  "1.c": {
    text: "Przekształć swoje otoczenie w przestrzeń pełną elegancji i funkcjonalności z nowoczesną wiatą wielostanowiskową. Wykonane z precyzyjnie dobranych materiałów, takich jak stal ocynkowana ogniowo, nasze konstrukcje stanowią synonim trwałości i minimalizmu w konserwacji. Dzięki tym rozwiązaniom, Twoja inwestycja pozostanie wolna od rdzy i uszkodzeń przez lata, bez potrzeby regularnych napraw. To idealne rozwiązanie dla każdego, kto ceni sobie jakość i estetykę – od dużych rodzin, przez deweloperów, aż po zarządców osiedli. Inwestując w nasze wiaty, inwestujesz w swoją przyszłość, podnosząc wartość i prestiż Twojej nieruchomości oraz zyskując pewność, że Twoje miejsce parkingowe pozostanie bezpieczne, stylowe i praktyczne na długie lata.",
    image: "zbrama.jpg",
    title: "Wiata wielostanowiskowa",
  },
  "1.d": {
    text: "Zapewnij swojemu pojazdowi i mieniu najwyższy poziom ochrony, wybierając wiatę z garażem. Nasze konstrukcje, gwarantują trwałość i odporność na różnorodne warunki atmosferyczne. Garaż stanowi dodatkowe zabezpieczenie, chroniąc nie tylko przed deszczem i śniegiem, ale także przed potencjalnymi aktami wandalizmu. Minimalistyczny wygląd i nowoczesne wykończenie sprawiają, że nasza wiata z garażem doskonale komponuje się z każdą posesją, podnosząc jej wartość i funkcjonalność."
    ,
    image: "wiatazgaraz.webp",
    title: "Wiata z garażem",
  },
  "1.e": {
    text: "Przekształć swoją posesję, wybierając wiatę z pomieszczeniem gospodarczym z boku, która łączy elegancję i funkcjonalność w jednym. Wykonana z wysokiej jakości materiałów, nasza konstrukcja gwarantuje trwałość i odporność na warunki atmosferyczne przez wiele lat. Pomieszczenie gospodarcze z boku to doskonałe rozwiązanie dla tych, którzy potrzebują dodatkowej przestrzeni do przechowywania narzędzi, sprzętu ogrodowego czy rowerów, bez rezygnacji z estetyki otoczenia.",
    image: "wiatazboku.webp",
    title: "Z pomieszczeniem z boku",
  },
  "1.f": {
    text: "Wybierz wiatę z pomieszczeniem gospodarczym z tyłu, aby stworzyć praktyczne i estetyczne miejsce na Twojej posesji. Nasze konstrukcje, wykonane z najlepszych materiałów, zapewniają nie tylko ochronę Twojego pojazdu, ale także dodatkową przestrzeń do przechowywania narzędzi, sprzętu ogrodowego czy innych niezbędnych przedmiotów. Pomieszczenie gospodarcze umieszczone z tyłu wiata nie zaburza harmonii otoczenia, a nowoczesny design sprawia, że całość prezentuje się stylowo. ",
    image: "wiataztylu.webp",
    title: "Z pomieszczeniem z tyłu",
  },
  "slider4": {
    text: "Zastrzały to fundament stabilności naszych wiat, zapewniając wytrzymałość konstrukcji i ochronę przed ekstremalnymi warunkami pogodowymi. Te wysokiej jakości stalowe elementy wzmocnieniowe są kluczowe dla rozprowadzania obciążeń, co czyni wiatę odporną na wiatr i śnieg, jednocześnie zachowując jej elegancką estetykę. Dzięki zastrzałom, nasze wiaty gwarantują niezawodność i trwałość, oferując bezpieczne schronienie dla Twoich pojazdów.",
    image: "zbrama.jpg",
    title: "Wysunięcie dachu",
  },
  "3.4.a": {
    text: "Nasze wiaty są zaprojektowane z myślą o zapewnieniu maksymalnej ochrony w różnych warunkach pogodowych, w tym w obszarach o zróżnicowanym obciążeniu śniegiem. Oferujemy modele dostosowane do 1., 2., i 3. strefy śniegowej, co pozwala na idealne dopasowanie konstrukcji do lokalnych warunków. Dzięki wykorzystaniu materiałów wysokiej jakości i przemyślanym rozwiązaniom konstrukcyjnym, nasze wiaty gwarantują trwałość i stabilność nawet w obliczu intensywnych opadów śniegu. Bez względu na to, czy potrzebujesz ochrony w łagodnym klimacie czy w rejonie narażonym na ciężkie warunki zimowe, nasze wiaty zapewnią niezawodną ochronę dla Twoich pojazdów i sprzętu.",
    image: "zbrama.jpg",
    title: "Strefa śniegowa",
  },
  "3.8.a": {
    text: "Każda wiata w zestawie posiada MASKOWNICĘ STANDARD. Zamów dodatkowo maskownicę profilowaną PREMIUM - nowoczesne i eleganckie rozwiązanie na pewno wzbogaci wygląd Twojej wiaty! Organiczna powłoka maskownic wykonana z termoplastycznej, dwuwarstwowej farby PVDF o grubości 35 μm i spolimeryzowanej w wysokiej temperaturze",
    image: "mask11.jpg",
    title: "Maskownica Standard/Premium",
  },
  "5.a": {
    text: "Panele wykonane ze stali falistej zapewniają całkowitą osłonę przed niekorzystnymi warunkami atmosferycznymi. Brak prześwitów gwarantuje większą prywatność, a trzymane wewnątrz przedmioty są niewidoczne."
    ,
    image: "scianka-falista.jpg",
    title: "Falista",
  },
  "5.b": {
    text: "Panel produkowany na bazie stalowego, giętego profilu. Szerokie lamele (10 cm) zapewniają niewielkie prześwity oraz kontakt wzrokowy z otoczeniem.",
    image: "scianka-horyzontal.jpg",
    title: "Horyzontal",
  },
  "5.c": {
    text: "Panel produkowany na bazie stalowego, giętego profilu. Wyróżnia się  wyjątkowo szerokimi lamelami (29 cm).",
    image: "scianka-modern.jpg",
    title: "Modern+",
  }, 
  "5.d": {
    text: "Nowoczesny system żaluzjowy zbudowany z profilu stalowego giętego. Z uwagi na prawie całkowitą zabudowę i brak bezpośrednich prześwitów zapewnia osłonę zawartości trzymanej wewnątrz pomieszczenia.",
    image: "scianka-zaluzja.jpg",
    title: "Żaluzja",
  },
};

//test