import React from "react";
import { Page, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
import ContactInfo from "./PageOne/ContactInfo";
import CustomerData from "./PageTwo/CustomerData";
import ConfigurationDimensions from "./PageTwo/ConfigurationDimensions";
import ShelterOptions from "./PageTwo/ShelterOptions";
import ColorInformation from "./PageTwo/ColorInformation";
import SidePanel from "./PageTwo/SidePanel";
import IntroSection from "./PageTwo/IntroSection"; // Import the renamed component

// Register the Open Sans font family
Font.register({
  family: "Open Sans",
  fonts: [
    { src: "/fonts/OpenSans-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/OpenSans-Bold.ttf", fontWeight: "bold" },
    {
      src: "/fonts/OpenSans-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    { src: "/fonts/OpenSans-ExtraBold.ttf", fontWeight: "800" },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 10,
    backgroundColor: "#f9f9f9",
    fontFamily: "Open Sans", // Default font is Open Sans
    fontSize: 7,
    color: "#333",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Ensures content is spaced out
    position: "relative", // Add position relative for the green lines
  },
  section: {
    padding: 5,
    backgroundColor: "#f9f9f9",
    border: "1px solid #e0e0e0",
  },
  heading: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 3,
  },
  paragraph: {
    fontSize: 7,
    padding: 5,
    fontFamily: "Open Sans",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableHeader: {
    fontSize: 8,
    fontWeight: "bold",
    padding: 5,
    borderBottom: "1px solid #e0e0e0",
  },
  tableData: {
    fontSize: 7,
    padding: 5,
  },
  content: {
    flexGrow: 1, // Fills the available space
  },
  contInfoContainer: {
    marginLeft: 19,
    flexShrink: 0, // Ensures this container won't shrink and stays at the bottom
  },
  // Add styles for the green lines from PageOne
  greenLineContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 120,
    height: 100,
  },
  greenLineOne: {
    position: "absolute",
    top: 3,
    left: 5.25,
    width: 5,
    height: 140,
    backgroundColor: "#7ac142",
  },
  greenLineTwo: {
    position: "absolute",
    top: 3,
    left: 27.5,
    width: 5,
    height: 70,
    backgroundColor: "#7ac142",
  },
});

const PageTwo = ({ data }) => (
  <Page style={styles.page} size="A4">
    {/* Add the green lines */}
    <View style={styles.greenLineContainer}>
      <View style={styles.greenLineOne}></View>
      <View style={styles.greenLineTwo}></View>
    </View>

    <View style={styles.content}>
      {/* Use the renamed IntroSection component */}
      <IntroSection data={data} />

      {/* Existing components */}
      <ConfigurationDimensions data={data} />

      <ShelterOptions data={data} />

      <ColorInformation data={data} />

      <SidePanel data={data} />

      <View style={styles.section}>
        <Text style={styles.heading}>Uwagi klienta</Text>
        <Text style={styles.paragraph}>{data.feedbackMessage}</Text>
      </View>
    </View>

    {/* ContactInfo will always stay at the bottom */}
    <View style={styles.contInfoContainer}>
      <ContactInfo />
    </View>
  </Page>
);

export default PageTwo;
