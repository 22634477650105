import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux"; 
import { setSalesmanName, setSalesmanPhoneNumber, setSalesmanEmail } from "../../../store/reducers/formInfoSlice"; // Import new actions

// Helper function to parse date and time
const parseDateTime = (dateString, timeString) => {
  const [day, month, year] = dateString.split(".");
  const [hours, minutes] = timeString.split(":");
  return new Date(year, month - 1, day, hours, minutes);
};

// Helper function to get the email template ID
const getEmailTemplateId = (harmonogram) => {
  const currentDate = new Date();

  // Filter out periods that have already ended
  const upcomingPeriods = harmonogram.filter((period) => {
    const endDateTime = parseDateTime(period["Data do"], period["Godzina do"]);
    return endDateTime >= currentDate;
  });

  for (const period of upcomingPeriods) {
    const startDateTime = parseDateTime(period["Data od"], period["Godzina od"]);
    const endDateTime = parseDateTime(period["Data do"], period["Godzina do"]);

    if (currentDate >= startDateTime && currentDate <= endDateTime) {
      return period.zmienna;  // Return the environment variable name
    }
  }

  return "REACT_APP_EMAILJS_TEMPLATE_ID_USER";  // Default template ID
};

// Salesman mappings
const salesmanMapping = {
  REACT_APP_EMAILJS_TEMPLATE_ID_USER: "Brak",
  REACT_APP_EMAILJS_TEMPLATE_ID_ROBERT: "Robert Mistewicz",
  REACT_APP_EMAILJS_TEMPLATE_ID_TEODOR: "Teodor Cimoszewicz",
  REACT_APP_EMAILJS_TEMPLATE_ID_MONIKA: "Monika Kowalczyk",
  REACT_APP_EMAILJS_TEMPLATE_ID_ANNA: "Anna Sykta",
  REACT_APP_EMAILJS_TEMPLATE_ID_MACIEJ: "Maciej Gajewski",
};

// Salesman phone number mappings
const salesmanPhoneNumberMapping = {
  REACT_APP_EMAILJS_TEMPLATE_ID_USER: "Brak",
  REACT_APP_EMAILJS_TEMPLATE_ID_ROBERT: "+48 573 009 924",
  REACT_APP_EMAILJS_TEMPLATE_ID_TEODOR: "+48 797 407 825",
  REACT_APP_EMAILJS_TEMPLATE_ID_MONIKA: "+48 690 536 294",
  REACT_APP_EMAILJS_TEMPLATE_ID_ANNA: "+48 531 762 612",
  REACT_APP_EMAILJS_TEMPLATE_ID_MACIEJ: "+48 690 606 380",
};

// Salesman email mappings
const salesmanEmailMapping = {
  REACT_APP_EMAILJS_TEMPLATE_ID_USER: "Brak",
  REACT_APP_EMAILJS_TEMPLATE_ID_ROBERT: "r.mistewicz@marciniakogrodzenia.pl",
  REACT_APP_EMAILJS_TEMPLATE_ID_TEODOR: "t.cimoszewicz@marciniakogrodzenia.pl",
  REACT_APP_EMAILJS_TEMPLATE_ID_MONIKA: "monika.kowalczyk@marciniakogrodzenia.pl",
  REACT_APP_EMAILJS_TEMPLATE_ID_ANNA: "a.sykta@marciniakogrodzenia.pl",
  REACT_APP_EMAILJS_TEMPLATE_ID_MACIEJ: "m.gajewski@marciniakogrodzenia.pl",
};

const useEmailTemplateSelector = () => {
  const [templateId, setTemplateId] = useState(null);
  const [harmonogram, setHarmonogram] = useState([]);
  const dispatch = useDispatch(); // Create dispatch instance

  useEffect(() => {
    const fetchHarmonogram = async () => {
      try {
        const response = await axios.get(
          "https://marciniakogrodzenia.pl/wp-content/uploads/2024/09/harmonogram.json"
        );
        setHarmonogram(response.data);
        const initialTemplateId = getEmailTemplateId(response.data);

        // Now get the actual secret template ID from environment variables
        const actualTemplateId = process.env[initialTemplateId];
        setTemplateId(actualTemplateId);

        // Set the salesman name, phone number, and email based on the environment variable name
        const initialSalesmanName = salesmanMapping[initialTemplateId] || "Brak";
        const initialSalesmanPhone = salesmanPhoneNumberMapping[initialTemplateId] || "Brak";
        const initialSalesmanEmail = salesmanEmailMapping[initialTemplateId] || "Brak";

        // Dispatch the salesman data to Redux store
        dispatch(setSalesmanName(initialSalesmanName));
        dispatch(setSalesmanPhoneNumber(initialSalesmanPhone));
        dispatch(setSalesmanEmail(initialSalesmanEmail));
      } catch (error) {
        //console.error("Error fetching harmonogram:", error);
      }
    };

    fetchHarmonogram();
  }, [dispatch]);

  useEffect(() => {
    if (harmonogram.length === 0) {
      return;
    }

    const updateTemplateId = () => {
      const newTemplateId = getEmailTemplateId(harmonogram);
      const actualTemplateId = process.env[newTemplateId];

      if (actualTemplateId !== templateId) {
        setTemplateId(actualTemplateId);
      }

      // Update the salesman name, phone number, and email based on the new template ID
      const salesmanName = salesmanMapping[newTemplateId] || "Brak";
      const salesmanPhone = salesmanPhoneNumberMapping[newTemplateId] || "Brak";
      const salesmanEmail = salesmanEmailMapping[newTemplateId] || "Brak";

      // Dispatch the updated salesman data
      dispatch(setSalesmanName(salesmanName));
      dispatch(setSalesmanPhoneNumber(salesmanPhone));
      dispatch(setSalesmanEmail(salesmanEmail));
    };

    // Update template ID and salesman data every minute
    const intervalId = setInterval(updateTemplateId, 60000);

    // Clean up interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [templateId, harmonogram, dispatch]);

  return templateId;
};

export default useEmailTemplateSelector;
