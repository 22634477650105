import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedId } from "../../store/reducers/modelChoiceSlice";
import {
  toggleGarage,
  toggleRoom,
  handleOptionChange,
  resetAdditionalOptions,
  resetWallOptions,
  toggleSnowZone,
  selectShedOptionsState,
  toggleShedPositionOption,
  toggleShedPosition,
  togglePhotovoltaicsReinforcement,
  toggleMontage,
  toggleGutterType,
  toggleGrilleType,
  toggleWallType,
} from "../../store/reducers/shedOptionsSlice";
import "./ShedOptionsComponent.css";
import PlusCheckboxComponent from "./PlusCheckboxComponent";
import CheckboxComponent from "./CheckboxComponent";
import { setSelectedIdFromOptions } from "../../store/reducers/modelChoiceSlice";
import { selectLastActionOriginShedOptions } from "../../store/reducers/shedOptionsSlice";
import HighlightedCheckboxComponent from "./HighlightedCheckboxComponent";

const ShedOptionsComponent = () => {
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const {
    isGarageChecked,
    isRoomChecked,
    selectedGarageOption,
    selectedRoomOption,
    isSnowZoneChecked,
    shedPosition,
    isShedPositionChecked,
    isPhotovoltaicsReinforcementChecked,
    isMontageChecked,
    isGutterTypeChecked,
    isGrilleTypeChecked,
    isWallTypeChecked,
  } = useSelector(selectShedOptionsState);

  const disableGarageAndRoom = selectedId === "1.a" || selectedId === "1.c";

  const lastActionOrigin = useSelector(selectLastActionOriginShedOptions);

  useEffect(() => {
    const origin = "ShedOptionsComponent";

    if (
      (selectedRoomOption === "3.2.c" || selectedRoomOption === "3.2.b") &&
      lastActionOrigin !== origin
    ) {
      let modelId = selectedRoomOption === "3.2.c" ? "1.e" : "1.f";
      dispatch(setSelectedIdFromOptions({ id: modelId, origin }));
    }
  }, [selectedRoomOption, dispatch, lastActionOrigin]);

  useEffect(() => {
    if (isGarageChecked || isRoomChecked) {
      dispatch(resetAdditionalOptions());
    }
  }, [isGarageChecked, isRoomChecked, dispatch, selectedId]);

  const handleGarageChange = () => {
    if (isRoomChecked) {
      dispatch(toggleRoom());
    }
    dispatch(toggleGarage());
    dispatch(resetWallOptions()); // Reset wall type option when changing the garage
  };

  const handleRoomChange = () => {
    if (isGarageChecked) {
      dispatch(toggleGarage());
    }
    dispatch(toggleRoom());
    dispatch(resetWallOptions()); // Reset wall type option when changing the room
  };

  const handleGarageOptionChange = (option) => {
    const origin = "ShedOptionsComponent";
    dispatch(handleOptionChange({ type: "garage", option, origin }));
    dispatch(resetWallOptions()); 
    if (option === "3.1.a" || option === "3.1.b") {
      dispatch(setSelectedIdFromOptions({ id: "1.d", origin }));
    }
  };

  const handleRoomOptionChange = (option) => {
    const origin = "ShedOptionsComponent";
    dispatch(handleOptionChange({ type: "room", option, origin }));
    dispatch(resetWallOptions()); 
    if (option === "3.2.a") {
      dispatch(setSelectedIdFromOptions({ id: "1.e", origin }));
    } else if (option === "3.2.b") {
      dispatch(setSelectedIdFromOptions({ id: "1.f", origin }));
    }
  };

  const isGarageOptionSelected = () => {
    return isGarageChecked;
  };

  const isRoomOptionSelected = () => {
    return isRoomChecked;
  };

  return (
    <div className="checkbox-container">
      <div className="checkbox-with-icon options-element">
        <PlusCheckboxComponent
          id="garage"
          checked={isGarageChecked}
          onChange={handleGarageChange}
          label="Garaż"
          labelClass={isGarageChecked ? "header-selected" : "header-unselected"}
          disabled={disableGarageAndRoom}
        />
      </div>
      <div
        className={`additional-checkboxes ${
          isGarageChecked ? "visible" : "hidden"
        }`}
      >
        <div className="indent-option">
          <CheckboxComponent
            id="3.1.a"
            checked={selectedGarageOption?.includes("3.1.a")}
            onChange={() => handleGarageOptionChange("3.1.a")}
            label="Garaż z lewej"
            labelClass={
              selectedGarageOption?.includes("3.1.a")
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isGarageChecked}
          />
        </div>
        <div className="indent-option">
          <CheckboxComponent
            id="3.1.b"
            checked={selectedGarageOption?.includes("3.1.b")}
            onChange={() => handleGarageOptionChange("3.1.b")}
            label="Garaż z prawej"
            labelClass={
              selectedGarageOption?.includes("3.1.b")
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isGarageChecked}
          />
        </div>
        <div
          className={`mt-2 indent-option  ${
            isGarageOptionSelected() ? "" : "hidden"
          }`}
        >
          <HighlightedCheckboxComponent
            id="3.9.a"
            checked={isWallTypeChecked}
            onChange={() => dispatch(toggleWallType())}
            labelBaseText="Rodzaj ściany"
            labels={{ unchecked: " pir ", checked: " fala " }}
            showInfoIcon={false}
          />
        </div>
      </div>
      {disableGarageAndRoom && (
        <div className="disabled-container">
          <span className="disabled-text">
            Opcja garażu dostępna tylko przy wiacie dwustanowiskowej
          </span>
        </div>
      )}
      <div className="checkbox-with-icon options-element">
        <PlusCheckboxComponent
          id="room"
          checked={isRoomChecked}
          onChange={handleRoomChange}
          label="Pomieszczenie"
          labelClass={isRoomChecked ? "header-selected" : "header-unselected"}
          disabled={disableGarageAndRoom}
        />
      </div>
      <div
        className={`additional-checkboxes ${
          isRoomChecked ? "visible" : "hidden"
        }`}
      >
        <div className="indent-option">
          <CheckboxComponent
            id="3.2.a"
            checked={selectedRoomOption === "3.2.a"}
            onChange={() => handleRoomOptionChange("3.2.a")}
            label="Pomieszczenie z lewej"
            labelClass={
              selectedRoomOption === "3.2.a"
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isRoomChecked}
          />
        </div>
        <div className="indent-option">
          <CheckboxComponent
            id="3.2.b"
            checked={selectedRoomOption === "3.2.b"}
            onChange={() => handleRoomOptionChange("3.2.b")}
            label="Pomieszczenie z tyłu"
            labelClass={
              selectedRoomOption === "3.2.b"
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isRoomChecked}
          />
        </div>
        <div className="indent-option">
          <CheckboxComponent
            id="3.2.c"
            checked={selectedRoomOption === "3.2.c"}
            onChange={() =>
              dispatch(handleOptionChange({ type: "room", option: "3.2.c" }))
            }
            label="Pomieszczenie z prawej"
            labelClass={
              selectedRoomOption === "3.2.c"
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isRoomChecked}
          />
        </div>
        <div
          className={`mt-2 indent-option  ${
            isRoomOptionSelected() ? "" : "hidden"
          }`}
        >
          <HighlightedCheckboxComponent
            id="4.0.a"
            checked={isWallTypeChecked}
            onChange={() => dispatch(toggleWallType())}
            labelBaseText="Rodzaj ściany"
            labels={{ unchecked: " pir ", checked: " fala " }}
            showInfoIcon={false}
          />
        </div>
      </div>
      {disableGarageAndRoom && (
        <div className="disabled-container">
          <span className="disabled-text">
            Opcja pomieszczenia dostępna tylko przy wiacie dwustanowiskowej
          </span>
        </div>
      )}
      <div className="checkbox-with-icon options-element">
        <PlusCheckboxComponent
          id="shedPosition"
          checked={isShedPositionChecked}
          onChange={() => dispatch(toggleShedPosition())}
          label="Położenie wiaty inne niż wolnostojąca"
          labelClass={
            isShedPositionChecked ? "header-selected" : "header-unselected"
          }
        />
      </div>
      <div
        className={`additional-checkboxes ${
          isShedPositionChecked ? "visible" : "hidden"
        }`}
      >
        <div className="indent-option">
          <CheckboxComponent
            id="3.3.a"
            checked={shedPosition.includes("3.3.a")}
            onChange={() => dispatch(toggleShedPositionOption("3.3.a"))}
            label="Przy ścianie z lewej strony"
            labelClass={
              shedPosition.includes("3.3.a")
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isShedPositionChecked}
          />
        </div>
        <div className="indent-option">
          <CheckboxComponent
            id="3.3.b"
            checked={shedPosition.includes("3.3.b")}
            onChange={() => dispatch(toggleShedPositionOption("3.3.b"))}
            label="Przy ścianie z prawej strony"
            labelClass={
              shedPosition.includes("3.3.b")
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isShedPositionChecked}
          />
        </div>
        <div className="indent-option">
          <CheckboxComponent
            id="3.3.c"
            checked={shedPosition.includes("3.3.c")}
            onChange={() => dispatch(toggleShedPositionOption("3.3.c"))}
            label="Przy ścianie z tyłu"
            labelClass={
              shedPosition.includes("3.3.c")
                ? "option-selected"
                : "option-unselected"
            }
            disabled={!isShedPositionChecked}
          />
        </div>
      </div>
      <div className="options-single-element mt-2">
        <CheckboxComponent
          id="3.4.a"
          checked={isSnowZoneChecked}
          onChange={() => dispatch(toggleSnowZone())}
          label="Strefa śniegowa"
          labelClass={
            isSnowZoneChecked ? "header-selected" : "header-unselected"
          }
          showInfoIcon={true}
        />
      </div>
      <div className="options-single-element mt-2">
        <CheckboxComponent
          id="3.5.a"
          checked={isPhotovoltaicsReinforcementChecked}
          onChange={() => dispatch(togglePhotovoltaicsReinforcement())}
          label="Wzmocnienie pod fotowoltaikę"
          labelClass={
            isPhotovoltaicsReinforcementChecked
              ? "header-selected"
              : "header-unselected"
          }
        />
      </div>
      <div className="options-single-element mt-2">
        <CheckboxComponent
          id="3.6.a"
          checked={isMontageChecked}
          onChange={() => dispatch(toggleMontage())}
          label="Montaż"
          labelClass={
            isMontageChecked ? "header-selected" : "header-unselected"
          }
        />
      </div>
    
      <div className="options-single-element mt-2">
        <HighlightedCheckboxComponent
          id="3.7.a"
          checked={isGutterTypeChecked}
          onChange={() => dispatch(toggleGutterType())}
          labelBaseText="Rodzaj rynny"
          labels={{ unchecked: " okrągła ", checked: " kwadratowa " }}
          showInfoIcon={false}
        />
      </div>
      <div className="options-single-element mt-2">
        <HighlightedCheckboxComponent
          id="3.8.a"
          checked={isGrilleTypeChecked}
          onChange={() => dispatch(toggleGrilleType())}
          labelBaseText="Rodzaj maskownicy"
          labels={{ unchecked: " standard ", checked: " premium " }}
          showInfoIcon={true}
        />
      </div>
    </div>
  );
};

export default ShedOptionsComponent;
