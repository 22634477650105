import ReactDOM from "react-dom/client";
import V3DApp from "./V3DApp";
import "./index.css";
import UiContainer from "./UiContainer";
import { Provider } from "react-redux";
import { store } from "./store/store";
import RotateIcon from "./components/miscellaneousComponents/RotateIcon/RotateIcon";
import SummaryButton from "./components/Summary/SummaryButton/SummaryButton";
import LogoIcon from "./components/miscellaneousComponents/LogoIcon/LogoIcon";
import { useState } from "react";
import MobileInfoIcon from "./components/miscellaneousComponents/InfoIcon/MobileInfoIcon";
import PriceCalculator from "./components/PriceCalculator/PriceCalculator";
import RedirectButton from "./components/miscellaneousComponents/RedirectButton/RedirectButton";
import InfoIcon from "./components/miscellaneousComponents/InfoIcon/InfoIcon";
import { ContextProvider } from "./ContextProvider";
import MobileMenu from "./components/MobileMenu/MobileMenu";

const App = () => {
  const [closeAll, setCloseAll] = useState(null);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <V3DApp />
      <div id="uicont" style={{ display: "none" }} className="uicotainerid">
        <UiContainer setCloseAll={setCloseAll} />
      </div>

      <RotateIcon />
      <div
        className="fixed-bottom-right"
        style={{ display: "none" }}
        id="summbtid"
      >
        <SummaryButton onCloseAll={closeAll} />
      </div>
      <LogoIcon />

      <div id="rdrbuttonid" style={{ display: "none" }}>
        <MobileInfoIcon />
        <InfoIcon />
        <MobileMenu/>
      </div>

      {/*
     <div className='price-calculator'>
        <PriceCalculator/>
      </div>
  */}
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>
);
