import React from "react";
import "./MobileMenu.css";
import MobileSummaryButton from "./MobileSummaryButton/MobileSummaryButton";
import MobileRedirectButton from "./MobileRedirectButton/MobileRedirectButton";
import ARButton from "../miscellaneousComponents/ARButton/ARButton";

const MobileMenu = () => {
  return (
    <div className="mobile-menu">
      <div className="ml-4">
        <MobileRedirectButton />
      </div>
  
      <div className="mr-7">
      <MobileSummaryButton />
      </div>
     
    </div>
  );
};

export default MobileMenu;
