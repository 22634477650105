const panelColorIdToName = {
  '7.a': 'Antracyt',
  '7.b': 'Ciemny brąz',
  '7.c': 'Jasny brąz',
  '7.d': 'Srebrny',
  '7.e': 'Czarny',
  '7.f': 'Biały',
};

export const translatePanelColorId = (colorId) => {
  return panelColorIdToName[colorId] || 'Unknown Color';
};
