import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selectPostalCode } from "../../store/reducers/formInfoSlice";
import { selectSelectedId as selectSelectedModelId } from "../../store/reducers/modelChoiceSlice";
import { selectDimensionsBySelectedId } from "../../store/reducers/dimensionsSlice";
import { selectShedOptionsState } from "../../store/reducers/shedOptionsSlice";
import { selectColorChoices } from "../../store/reducers/colorChoiceSlice";
import { selectSelectedId as isSelectedPanelColorId } from "../../store/reducers/panelColorChoiceSlice";
import { selectSelectedId as selectSelectedPanelId } from "../../store/reducers/panelChoiceSlice";
import { selectSelectedSquares } from "../../store/reducers/panelPlacementSlice";
import {
  countSelectedSquaresByModel,
  modelToPanelPrefix,
} from "../Summary/PDFGenerationUtils/choiceTranslationUtils/countingUtils";
import { calculateGaragePrice, calculateGatePrice } from "./garagePriceUtils";
import { calculateRoomPrice } from "./roomPriceUtils";
import { getPriceForDimensions } from "./dimensionPriceUtils";
import {
  getAntykondensatPrice,
  calculateAntykondensatCost,
} from "./antykondensatPriceUtils";
import { getPhotovoltaicsReinforcementPrice } from "./photovoltaicsPriceUtils";
import { getPanelPrice, calculatePanelsPrice } from "./panelsPriceUtils";
import { calculateMaskownicaPremiumPrice } from "./maskownicaPriceUtils";
import { calculateGutterPrice } from "./gutterPriceUtils";
import { calculateNumberOfPoles, calculatePolePrice } from "./polesPriceUtils";
import {
  setFinalPrice,
  setFinalBruttoPrice,
  setDimensionPrice,
  setPanelPrice,
  setPanelsPrice,
  setPhotovoltaicsReinforcementPrice,
  setAntykondensatCost,
  setRoomPrice,
  setGaragePrice,
  setGatePrice,
  setMaskownicaPrice,
  setMaskownicaPremiumPrice,
  setPolePrice,
  setGutterPrice,
} from "../../store/reducers/finalPriceSlice";

const PriceCalculator = () => {
  const [dimensionsData, setDimensionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [finalPrice, setFinalPriceState] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://marciniakogrodzenia.pl/wp-content/uploads/2024/07/compiled_data2.json"
        );
        setDimensionsData(response.data);
        setLoading(false);
      } catch (err) {
        setError(`Error fetching data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const postalCode = useSelector(selectPostalCode);
  const selectedModelId = useSelector(selectSelectedModelId);
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );
  const shedOptions = useSelector(selectShedOptionsState);
  const colorChoices = useSelector(selectColorChoices);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const garageOptions = shedOptions.selectedGarageOption;
  const roomOptions = shedOptions.selectedRoomOption;
  const isWallTypeChecked = shedOptions.isWallTypeChecked;

  useEffect(() => {
    if (!loading && !error) {
      let calculatedPrice = 0;

      const numberOfPoles = calculateNumberOfPoles(
        selectedModelId,
        dimensions.length,
        selectedSquares
      );

      const panelPrice = getPanelPrice(
        selectedPanelId,
        selectedPanelColorId,
        dimensionsData,
        dimensions.height
      );

      //console.log(`koszt pojedyńczego panelu: ${panelPrice}`);

      dispatch(setPanelPrice(panelPrice));

      let panelsPrice = calculatePanelsPrice(
        selectedModelId,
        dimensions,
        selectedSquares,
        shedOptions,
        garageOptions,
        panelPrice,
        modelToPanelPrefix,
        countSelectedSquaresByModel
      );

      //console.log(`koszt łączny paneli: ${panelsPrice}`);
      //TUTAJ MNOZYMY?
      //console.log(`koszt łączny paneli: ${panelsPrice}`);
      //panelsPrice = panelsPrice * 1.25
      //console.log(`koszt łączny paneli po przemnozeniu: ${panelsPrice}`);
      calculatedPrice += panelsPrice;

      dispatch(setPanelsPrice(panelsPrice));

      const photovoltaicReinforcementPrice = getPhotovoltaicsReinforcementPrice(
        dimensionsData,
        dimensions
      );

      if (shedOptions.isPhotovoltaicsReinforcementChecked) {
        //console.log(`fotowoltaika koszt: ${photovoltaicReinforcementPrice}`);
        //photovoltaicReinforcementPrice = photovoltaicReinforcementPrice * 1.25
        //console.log(`koszt łączny wzmocnienia pod fotowoltaikę po przemnozeniu: ${photovoltaicReinforcementPrice}`);
        calculatedPrice += photovoltaicReinforcementPrice;
        dispatch(
          setPhotovoltaicsReinforcementPrice(photovoltaicReinforcementPrice)
        );
      }

      let roomPrice = 0;
      let garagePrice = 0;
      let gatePrice = 0;

      if (shedOptions.isRoomChecked) {
        roomPrice = calculateRoomPrice(
          dimensionsData,
          dimensions,
          shedOptions.selectedRoomOption,
          isWallTypeChecked
        );
      }

      if (shedOptions.isGarageChecked) {
        garagePrice = calculateGaragePrice(
          dimensions,
          garageOptions,
          dimensionsData,
          isWallTypeChecked
        );
        gatePrice = calculateGatePrice(
          dimensions,
          garageOptions,
          dimensionsData
        );
      }

      const facadeColor = colorChoices.facadeColor;
      const facadeColorIds = ["4.3.b", "4.3.c", "4.3.d", "4.3.e", "4.3.f"];
      if (facadeColorIds.includes(facadeColor)) {
        const maskownicaStandard = dimensionsData.find(
          (d) => d["Rozmiar (szer. x dł.)"] === "Maskownica Standard"
        );
        const maskownicaPrice = maskownicaStandard
          ? maskownicaStandard["Cena Netto (zł) zaciagana do konfiguratora"]
          : 0;
        //console.log(`koszt łączny maskownicy przed przemnozeniem: ${maskownicaPrice}`);
        //maskownicaPrice = maskownicaPrice * 1.25
        //console.log(`koszt łączny maskownicy po przemnozeniu: ${maskownicaPrice}`);
        calculatedPrice += maskownicaPrice;
        dispatch(setMaskownicaPrice(maskownicaPrice)); // Dispatch the maskownicaPrice
        //console.log(`koszt maskownicy: ${maskownicaPrice}`);
      }

      const maskownicaPremiumPrice = calculateMaskownicaPremiumPrice(
        dimensionsData,
        dimensions,
        shedOptions
      );
      //console.log(`koszt maskownicy premium przed przemnozeniem: ${maskownicaPremiumPrice}`);
      //maskownicaPremiumPrice = maskownicaPremiumPrice * 1.25
      //console.log(`koszt maskownicy premium po przemnozeniu: ${maskownicaPremiumPrice}`);
      calculatedPrice += maskownicaPremiumPrice;
      dispatch(setMaskownicaPremiumPrice(maskownicaPremiumPrice));

      const singlePolePrice =
        dimensionsData.find(
          (d) =>
            d["Rozmiar (szer. x dł.)"] ===
            "Słup pojedynczy \ndla wysokości 2,7m"
        )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

      const polePrice = calculatePolePrice(
        dimensions,
        numberOfPoles,
        selectedModelId,
        selectedSquares,
        singlePolePrice,
        dimensionsData,
        shedOptions
      );
      //console.log(`ilosc słupów: ${numberOfPoles}`);
      //console.log(`koszt słupów: ${polePrice}`);
      //console.log(`koszt łączny slupów przed przemnozeniem: ${polePrice}`);
      //polePrice = polePrice * 1.25
      //console.log(`kkoszt łączny slupów po przemnozeniu: ${polePrice}`);
      calculatedPrice += polePrice;
      dispatch(setPolePrice(polePrice));

      const gutterPrice = calculateGutterPrice(
        dimensionsData,
        shedOptions.isGutterTypeChecked
      );
      //console.log(`koszt rynny przed przemnozeniem: ${gutterPrice}`);
      //gutterPrice = gutterPrice * 1.25
      //console.log(`koszt rynny po przemnozeniu: ${gutterPrice}`);
      calculatedPrice += gutterPrice;
      dispatch(setGutterPrice(gutterPrice)); // Dispatch the gutterPrice

      if (dimensions && dimensions.length && dimensions.width) {
        let dimensionPrice = getPriceForDimensions(
          dimensionsData,
          dimensions.length,
          dimensions.width
        );
        //console.log(`Dimension price before adding singlePolePrice and panelPrice: ${dimensionPrice}`);
        if (
          selectedModelId === "1.b" &&
          (selectedSquares["6.1.d"] || selectedSquares["6.1.e"])
        ) {
          const singlePolePrice =
            dimensionsData.find(
              (d) =>
                d["Rozmiar (szer. x dł.)"] ===
                "Słup pojedynczy \ndla wysokości 2,7m"
            )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

          dimensionPrice += singlePolePrice + panelPrice;
          //console.log(`Dimension price after adding singlePolePrice (${singlePolePrice}) and panelPrice (${panelPrice}): ${dimensionPrice}`);
        }

        if (selectedModelId === "1.c") {
          let additionalPolesAndPanels = 0;

          if (selectedSquares["6.1.d"] && selectedSquares["6.1.e"]) {
            additionalPolesAndPanels += 2 * (singlePolePrice + panelPrice);
          } else if (selectedSquares["6.1.d"] || selectedSquares["6.1.e"]) {
            additionalPolesAndPanels += singlePolePrice + panelPrice;
          }

          dimensionPrice += additionalPolesAndPanels;
          // console.log(`Dimension price after adding poles and panels for "1.c": ${dimensionPrice}`);
        }

        // Log the values of garageOptions, selectedSquares, and selectedModelId
        //console.log("Garage Options:", garageOptions);
        //console.log("Selected Squares:", selectedSquares);
        //console.log("Selected Model ID:", selectedModelId);

        // Only execute the logic if selectedModelId is 1.d
        if (selectedModelId === "1.d") {
          const singlePolePrice =
            dimensionsData.find(
              (d) =>
                d["Rozmiar (szer. x dł.)"] ===
                "Słup pojedynczy \ndla wysokości 2,7m"
            )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

          // Check if both garageOptions 3.1.a and 3.1.b are selected, do not add prices in this case
          if (
            garageOptions.includes("3.1.a") &&
            garageOptions.includes("3.1.b")
          ) {
            // console.log(
            // "Both 3.1.a and 3.1.b are selected. No prices will be added."
            // );
          }

          // Add price if garageOptions includes "3.1.a" and "6.1.e" is selected
          else if (
            garageOptions.includes("3.1.a") &&
            selectedSquares["6.1.e"]
          ) {
            dimensionPrice += singlePolePrice + panelPrice;
            //  console.log(`Added singlePolePrice (${singlePolePrice}) and panelPrice (${panelPrice}) for 3.1.a and 6.1.e selected. Dimension price: ${dimensionPrice}`);
          }

          // Add price if garageOptions includes "3.1.b" and "6.1.d" is selected
          else if (
            garageOptions.includes("3.1.b") &&
            selectedSquares["6.1.d"]
          ) {
            dimensionPrice += singlePolePrice + panelPrice;
            // console.log( `Added singlePolePrice (${singlePolePrice}) and panelPrice (${panelPrice}) for 3.1.b and 6.1.d selected. Dimension price: ${dimensionPrice}`);
          }

          // If neither condition is met, do nothing
          else {
            //console.log("Conditions not met. No prices added.");
          }
        }

        if (
          selectedModelId === "1.e" &&
          (selectedSquares["6.1.d"] || selectedSquares["6.1.e"])
        ) {
          const singlePolePrice =
            dimensionsData.find(
              (d) =>
                d["Rozmiar (szer. x dł.)"] ===
                "Słup pojedynczy \ndla wysokości 2,7m"
            )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

          dimensionPrice += singlePolePrice + panelPrice;
          // console.log(`Dimension price after adding singlePolePrice (${singlePolePrice}) and panelPrice (${panelPrice}): ${dimensionPrice}`);
        }
        //console.log(dimensionPrice)
        const antykondensatPrice = getAntykondensatPrice(dimensionsData);
        const antykondensatCost = calculateAntykondensatCost(
          dimensions,
          antykondensatPrice
        );
        //console.log(`koszt antykondensatu: ${antykondensatCost}`);
        dimensionPrice += antykondensatCost;
        dispatch(setAntykondensatCost(antykondensatCost)); // Dispatch the antykondensatCost
        //console.log(`Dimension price after koszt antykondensatu: ${antykondensatCost}: ${dimensionPrice}`);

        // Dispatch the final dimension price
        //console.log(`koszt wymiarow(z antykondensatem, i doliczonymi slupami) przed przemnozeniem: ${dimensionPrice}`);
        //dimensionPrice = dimensionPrice * 1.25
        //console.log(`koszt wymiarow(z antykondensatem, i doliczonymi slupami) po przemnozeniu: ${dimensionPrice}`);
        calculatedPrice += dimensionPrice;
        dispatch(setDimensionPrice(dimensionPrice));
      }
      //console.log(` finalna cena przed pomieszczeniem/garazem ${calculatedPrice}`)
      // Ensure only one price is sent, based on the final selection
      if (shedOptions.isRoomChecked && !shedOptions.isGarageChecked) {
        //console.log(`koszt pomieszczenia przed przemnozeniem: ${roomPrice}`);
        //roomPrice = roomPrice * 1.25
        //console.log(`koszt pomieszczenia po przemnozeniu: ${roomPrice}`);
        calculatedPrice += roomPrice;
        dispatch(setRoomPrice(roomPrice));
        dispatch(setGaragePrice(0));
        dispatch(setGatePrice(0));
      } else if (shedOptions.isGarageChecked && !shedOptions.isRoomChecked) {
        //console.log(`koszt garazu przed przemnozeniem: ${garagePrice},  koszt bramy przed przemnozeniem: ${gatePrice}, `);
        //garagePrice = garagePrice * 1.25
        //gatePrice = gatePrice * 1.25
        //console.log(`koszt garazu po przemnozeniu: ${garagePrice},  koszt bramy po przemnozeniu: ${gatePrice}, łączny koszt ${garagePrice + gatePrice} `);
        calculatedPrice += garagePrice + gatePrice;
        dispatch(setGaragePrice(garagePrice));
        dispatch(setGatePrice(gatePrice));
        dispatch(setRoomPrice(0));
      } else {
        dispatch(setRoomPrice(0));
        dispatch(setGaragePrice(0));
        dispatch(setGatePrice(0));
      }
      //console.log(` finalna cena po pomieszczeniu/garazu ${calculatedPrice}`)
      const finalBruttoPrice = calculatedPrice * 1.23;
      dispatch(setFinalBruttoPrice(finalBruttoPrice)); // Dispatch the finalBruttoPrice
      //console.log(` finalne brutto ${finalBruttoPrice}`)
      // Update state and dispatch the final price to the store
      setFinalPriceState(calculatedPrice);
      dispatch(setFinalPrice(calculatedPrice));
    }
  }, [
    loading,
    error,
    dimensionsData,
    dispatch,
    selectedModelId,
    dimensions,
    selectedSquares,
    shedOptions,
    colorChoices,
    selectedPanelColorId,
    selectedPanelId,
    garageOptions,
    isWallTypeChecked,
  ]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const numberOfPoles = calculateNumberOfPoles(
    selectedModelId,
    dimensions.length,
    selectedSquares
  );

  return (
    <div>
      {/*
           <div>
        Szacowana cena netto: {finalPrice}
        </div>
      <div>Szacowana cena brutto: {(finalPrice * 1.23).toFixed(2)}</div>
      <div>Ilość słupów: {numberOfPoles}</div>
      
      */}
    </div>
  );
};

export default PriceCalculator;
