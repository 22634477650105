// PageOne.jsx
import React from "react";
import { Page, Image, View, StyleSheet, Font } from "@react-pdf/renderer";
import ContentContainer from "./PageOne/ContentContainer";
import ContactInfo from "./PageOne/ContactInfo"; // Import the ContactInfo component

// Register Fonts

// Registering fonts
Font.register({
    family: "Roboto",
    fonts: [
      { src: "/fonts/Roboto-Regular.ttf", fontWeight: "normal" },
      { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
      { src: "/fonts/Roboto-Italic.ttf", fontStyle: "italic" },
      {
        src: "/fonts/Roboto-BoldItalic.ttf",
        fontWeight: "bold",
        fontStyle: "italic",
      },
      { src: "/fonts/Roboto-Light.ttf", fontWeight: "light" },
      { src: "/fonts/Roboto-Medium.ttf", fontWeight: "medium" },
    ],
});

Font.register({
    family: "Liberation Serif",
    fonts: [
      { src: "/fonts/LiberationSerif-Regular.ttf", fontWeight: "normal" },
      { src: "/fonts/LiberationSerif-Bold.ttf", fontWeight: "bold" },
      { src: "/fonts/LiberationSerif-Italic.ttf", fontStyle: "italic" },
      {
          src: "/fonts/LiberationSerif-BoldItalic.ttf",
          fontWeight: "bold",
          fontStyle: "italic",
      },
    ],
});
// Styles for Page One
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Roboto",
    fontSize: 7,
    color: "#333",
    padding: 20,
    position: "relative",
  },
  logo: {
    width: 120,
    height: "auto",
    marginBottom: 10,
    alignSelf: "center",
  },
  greenLineContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 120,
    height: 100,
  },
  greenLineOne: {
    position: "absolute",
    top: 0,
    left: 88.18,
    width: 5,
    height: 140,
    backgroundColor: "#7ac142",
  },
  greenLineTwo: {
    position: "absolute",
    top: 0,
    left: 109.72,
    width: 5,
    height: 70,
    backgroundColor: "#7ac142",
  },
  imageContainer: {
    marginTop: 10,
    alignSelf: "center",
  },
  image: {
    width: 600,
    height: "auto",
  },
  contactInfoContainer: {
    marginTop: 46,
    marginLeft:9.5
  },
});

const PageOne = ({ data }) => (
  <Page style={styles.page} size="A4">
    {/* Logo */}
    <Image style={styles.logo} src="/marcilogo.png" />

    {/* Container for Green Lines */}
    <View style={styles.greenLineContainer}>
      <View style={styles.greenLineOne}></View>
      <View style={styles.greenLineTwo}></View>
    </View>

    {/* Content Container */}
    <ContentContainer  data={data}/>

    {/* Image Container */}
    <View style={styles.imageContainer}>
      <Image style={styles.image} src="/wiatka.png" />
    </View>

    {/* Contact Information */}
    <View style={styles.contactInfoContainer}>
    <ContactInfo />
    </View>
  </Page>
);

export default PageOne;
