import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

// Registering fonts
Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
    { src: "/fonts/Roboto-Italic.ttf", fontStyle: "italic" },
    {
      src: "/fonts/Roboto-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    { src: "/fonts/Roboto-Light.ttf", fontWeight: "light" },
    { src: "/fonts/Roboto-Medium.ttf", fontWeight: "medium" },
  ],
});

Font.register({
  family: "Liberation Serif",
  fonts: [
    { src: "/fonts/LiberationSerif-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/LiberationSerif-Bold.ttf", fontWeight: "bold" },
    { src: "/fonts/LiberationSerif-Italic.ttf", fontStyle: "italic" },
    {
      src: "/fonts/LiberationSerif-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

// Styles for Content Container
const styles = StyleSheet.create({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 51.53,
    marginLeft: 134.31,
  },
  ofertaText: {
    fontSize: 82.3,
    fontWeight: "bold",
    color: "#3b3b3b",
    marginBottom: 10,
    letterSpacing: 4,
  },
  titleText: {
    fontSize: 17.9,
    color: "#82c225",
    letterSpacing: 6,
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 10.1,
    fontWeight: "bold",
    color: "#000000",
    marginBottom: 5,
  },
});

// Function to format the current date
const getCurrentDate = () => {
  const date = new Date();
  return date.toLocaleDateString("pl-PL", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// List of allowed salesman names
const allowedSalesmanNames = [
  "Robert Mistewicz",
  "Teodor Cimoszewicz",
  "Monika Kowalczyk",
  "Anna Sykta",
  "Maciej Gajewski",
];

const ContentContainer = ({ data }) => (
  <View style={styles.contentContainer}>
    {/* Title Text */}
    <Text style={styles.titleText}>TWOJA WIATA GARAŻOWA</Text>
    <Text style={styles.ofertaText}>OFERTA</Text>

    {/* Information Text Lines */}
    <Text style={styles.infoText}>OFERTA NR: {data.orderNumber}</Text>
    <Text style={styles.infoText}>
      DATA STWORZENIA OFERTY: {getCurrentDate()}
    </Text>

    {/* Conditionally render salesman information */}
    {data.salesmanName !== "Brak" && allowedSalesmanNames.includes(data.salesmanName) && (
      <>
       <Text style={styles.infoText}>OPIEKUN KLIENTA: {data.salesmanName}</Text>
       <Text style={styles.infoText}> {data.salesmanPhoneNumber}</Text>
       <Text style={styles.infoText}>{data.salesmanEmail}</Text>
      </>
     
    )}
  </View>
);

export default ContentContainer;
