import React, { useState, useEffect } from "react";
import "./SummaryFormComponent.css";
import SummaryInput from "./components/SummaryInput";
import CloseSummaryButton from "./components/buttons/CloseSummaryButton";
import useCaptcha from "../../../hooks/useCaptcha";
import { useDispatch, useSelector } from "react-redux";
import FeedbackInput from "./components/FeedbackInput";
import {
  setEmail,
  setFullName,
  setPhone,
  setPostalCode,
  setFeedbackMessage,
  selectEmail,
  selectFullName,
  selectPhone,
  selectPostalCode,
  selectFeedbackMessage,
} from "../../../store/reducers/formInfoSlice";
import {
  validateEmail,
  validatePhone,
  validatePostalCode,
} from "./formValidators";
import emailjs from "@emailjs/browser";
import SendPdfButton from "./components/buttons/SendPdfButton";
import EmailSendingIndicatorComponent from "./components/EmailSendingIndicatorComponent";
import captureV3dContainerScreenshot from "../../../utils/captureV3dContainerScreenshot";
import PriceCalculator from "../../PriceCalculator/PriceCalculator";
import useFirstAndSecondBusinessEmailRequest from "./useFirstAndSecondBusinessEmailRequest";
import ComprehensivePDFDocument from "./ComprehensivePDFDocument";
import useSetPDFParams from "./useSetPDFParams";
import useSetTemplateParams from "./useSetTemplateParams";
import useEmailTemplateSelector from "./useEmailTemplateSelector";
import { PDFDocument as PDFLib } from "pdf-lib";
import { pdf } from "@react-pdf/renderer";

const SummaryFormComponent = ({ onClose }) => {
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [mergedPdf, setMergedPdf] = useState(null);
  const [isMerging, setIsMerging] = useState(false);

  const {
    captchaQuestion,
    userCaptchaInput,
    handleCaptchaInputChange,
    isCaptchaSolved,
    resetCaptcha,
  } = useCaptcha();
  const { sendFirstAndSecondBusinessEmail } =
    useFirstAndSecondBusinessEmailRequest(setIsLoading, resetCaptcha, setAlert);
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage = useSelector(selectFeedbackMessage);
  const isEmailValid = validateEmail(email);
  const isPostalCodeValid = validatePostalCode(postalCode);
  const isPhoneValid = validatePhone(phone);
  const isButtonEnabled =
    isEmailValid &&
    isPostalCodeValid &&
    isPhoneValid &&
    privacyPolicyChecked &&
    isCaptchaSolved;

  const pdfData = useSetPDFParams();
  const setTemplateParams = useSetTemplateParams();
  const emailTemplateId = useEmailTemplateSelector();

  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  const handlePrivacyCheckbox = (event) => {
    setPrivacyPolicyChecked(event.target.checked);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue =
          "Proces wysyłania Twojej konfiguracji nadal trwa! Czy na pewno chcesz opuścić konfigurator?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  const mergePDFs = async () => {
    setIsMerging(true);
    try {
      // Generate the main PDF from your React component
      const mainPdfBlob = await pdf(
        <ComprehensivePDFDocument data={pdfData} />
      ).toBlob();
  
      // Load the main PDF and the additional PDF
      const mainPdfDoc = await PDFLib.load(await mainPdfBlob.arrayBuffer());
      const additionalPdfDoc = await PDFLib.load(
        await fetch("/testpdf2.pdf").then((res) => res.arrayBuffer())
      );
  
      // Create a new PDF document to merge the pages into
      const mergedPdfDoc = await PDFLib.create();
  
      // Copy pages from the main PDF into the merged document
      const mainPdfPages = await mergedPdfDoc.copyPages(
        mainPdfDoc,
        mainPdfDoc.getPageIndices()
      );
      mainPdfPages.forEach((page) => {
        mergedPdfDoc.addPage(page); // Add each page without scaling
      });
  
      // Copy pages from the additional PDF into the merged document
      const additionalPdfPages = await mergedPdfDoc.copyPages(
        additionalPdfDoc,
        additionalPdfDoc.getPageIndices()
      );
      additionalPdfPages.forEach((page) => {
        mergedPdfDoc.addPage(page); // Add each page without scaling
      });
  
      // Save the merged PDF
      const mergedPdfBytes = await mergedPdfDoc.save();
  
      // Convert to Blob and return the PDF for download or further processing
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
  
      setMergedPdf(mergedPdfBlob); // Update the state for download link
      return mergedPdfBlob; // Return the merged PDF blob
    } catch (error) {
      console.error("Error merging PDFs:", error);
      throw error; // Rethrow to handle in the calling function
    } finally {
      setIsMerging(false);
    }
  };
  
  
  

  // Utility function to convert Uint8Array to Base64
  function uint8ArrayToBase64(uint8Array) {
    let binaryString = "";
    const chunkSize = 0x8000; // 32KB

    for (let i = 0; i < uint8Array.length; i += chunkSize) {
      const chunk = uint8Array.subarray(i, i + chunkSize);
      binaryString += String.fromCharCode.apply(null, chunk);
    }

    return btoa(binaryString);
  }

  const sendEmailWithPDFAttachment = async () => {
    if (
      !isEmailValid ||
      !isPostalCodeValid ||
      !privacyPolicyChecked ||
      !isPhoneValid
    ) {
      setAlert({
        show: true,
        message: "Proszę wypełnić wymagane pola!",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return;
    }

    if (!isCaptchaSolved) {
      setAlert({
        show: true,
        message: "Proszę poprawnie wypełnić CAPTCHA!",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return;
    }

    setIsLoading(true);

    // Generate merged PDF and convert to base64
    let pdfBase64;
    try {
      const mergedPdfBlob = await mergePDFs(); // Use the returned blob

      // Now use mergedPdfBlob directly
      if (!mergedPdfBlob) {
        throw new Error("Merged PDF is not available.");
      }

      const mergedPdfArrayBuffer = await mergedPdfBlob.arrayBuffer();
      const mergedPdfUint8Array = new Uint8Array(mergedPdfArrayBuffer);
      pdfBase64 = uint8ArrayToBase64(mergedPdfUint8Array);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setAlert({
        show: true,
        message: "Wystąpił błąd podczas generowania PDF.",
        type: "error",
      });
      setIsLoading(false);
      return;
    }

    let imageString;
    try {
      const imageDataUrl = await captureV3dContainerScreenshot();
      imageString = imageDataUrl.split(",")[1];
    } catch (error) {
      console.error("Error capturing screenshot:", error);
      imageString = ""; // Or handle accordingly
    }

    // Send any business emails if required
    await sendFirstAndSecondBusinessEmail(pdfBase64);

    const templateParams = setTemplateParams(imageString, pdfBase64);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        emailTemplateId,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          setAlert({
            show: true,
            message: "Konfiguracja została wysłana na Twój email!",
            type: "success",
          });
        },
        (err) => {
          console.error("EmailJS Error:", err);
          setAlert({
            show: true,
            message:
              "Wystąpił błąd - proszę spróbuj ponownie za kilka minut",
            type: "error",
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
        resetCaptcha();
        setTimeout(() => setAlert({ show: false, message: "", type: "" }), 3000);
      });
  };

  return (
    <div className="popup-overlay ">
      <div className="popup-content">
        <div className="finalization-summary-container">
          <CloseSummaryButton onClick={onClose} />

          <div className="input-fields">
            <div className="summary-row">
              <SummaryInput
                placeholder="*Adres e-mail"
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                isRequired={true}
                isValid={isEmailValid}
                onClick={handleInputClick}
                icon="Email.png"
              />
              <SummaryInput
                placeholder="Imię i nazwisko (opcjonalne)"
                value={fullName}
                name="to_name"
                onChange={(e) => dispatch(setFullName(e.target.value))}
                onClick={handleInputClick}
                icon="User.png"
              />
            </div>
            <div className="summary-row">
              <SummaryInput
                placeholder="*Telefon"
                value={phone}
                onChange={(e) => dispatch(setPhone(e.target.value))}
                onClick={handleInputClick}
                icon="Phone.png"
                isValid={isPhoneValid}
                isRequired={true}
              />
              <SummaryInput
                placeholder="*00-000 (Kod pocztowy)"
                value={postalCode}
                onChange={(e) => dispatch(setPostalCode(e.target.value))}
                onClick={handleInputClick}
                isRequired={true}
                isValid={isPostalCodeValid}
                icon="Home.png"
              />
            </div>
            <div className="postal-description">
              Kod pocztowy jest niezbędny do określenia strefy śniegowej
              potrzebnej do dokładnej wyceny.
            </div>
            <div className="cost-description">
              Wycena trwa zwykle do 2-3 godzin
            </div>
            <FeedbackInput
              placeholder="Uwagi klienta"
              value={feedbackMessage}
              onChange={(e) => dispatch(setFeedbackMessage(e.target.value))}
              isRequired={false}
            />
          </div>
          <div className="finalization-container">
            <div className="captcha">
              <div className="captchaquestion">
                <span>{captchaQuestion} = </span>
                <SummaryInput
                  value={userCaptchaInput}
                  onChange={handleCaptchaInputChange}
                  placeholder=" "
                />
              </div>
            </div>

            <div className="buttons-row">
              <PriceCalculator />

              {isMerging ? (
                <p  style={{
                  display:"none"
                }}>Przygotowywanie PDF do pobrania...</p>
              ) : mergedPdf ? (
                <a
                  href={URL.createObjectURL(mergedPdf)}
                  download="merged_configuration.pdf"
                  style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#fff",
                    backgroundColor: "#007bff",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display:"none"
                  }}
                >
                  Pobierz połączony PDF
                </a>
              ) : (
                <button
                  onClick={mergePDFs}
                  style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#fff",
                    backgroundColor: "#007bff",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display:"none"
                  }}
                >
                  Pobierz połączony PDF
                </button>
              )}

              <SendPdfButton
                onClick={sendEmailWithPDFAttachment}
                isButtonEnabled={isButtonEnabled}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="privacy-policy">
            <input
              type="checkbox"
              checked={privacyPolicyChecked}
              onChange={handlePrivacyCheckbox}
            />
            <span>
              *Zapoznałam/łem się z{" "}
              <a
                href="https://marciniakogrodzenia.pl/politykaprywatnosci/"
                target="_blank"
                rel="noopener noreferrer"
              >
                polityką prywatności
              </a>
            </span>
          </div>
        </div>
      </div>

      {isLoading && (
        <div>
          <div className="spinner"></div>
          <div className="loading-text">
            Proszę czekać, Twój email jest wysyłany
          </div>
        </div>
      )}
      {alert.show && (
        <EmailSendingIndicatorComponent
          message={alert.message}
          type={alert.type}
        />
      )}
    </div>
  );
};

export default SummaryFormComponent;
