import React, { useState, useEffect } from 'react';
import './RedirectButton.css';
import HomeIcon1 from './hd1.png';
import HomeIcon2 from './hd2.png';
import RedirectHomePopup from '../RedirectHomePopup/RedirectHomePopup';

const RedirectButton = () => {
  const [icon, setIcon] = useState(HomeIcon1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleRedirect = () => {
    setIsPopupOpen(true);
  };

  const handleMouseEnter = () => {
    setIcon(HomeIcon2);
  };

  const handleMouseLeave = () => {
    setIcon(HomeIcon1);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <button
        className="redirect-button"
        onClick={handleRedirect}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src={icon} alt="Home" className="redirect-icon" />
      </button>
      {isPopupOpen && <RedirectHomePopup onClose={handleClosePopup} />}
    </>
  );
};

export default RedirectButton;
