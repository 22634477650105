import {
  countSelectedSquaresByModel,
  modelToPanelPrefix,
} from "../PDFGenerationUtils/choiceTranslationUtils/countingUtils";

export const calculateSelectedSquares = (
  selectedSquares,
  selectedModelId,
  dimensions,
  shedOptions
) => {
  const modelPrefix = modelToPanelPrefix[selectedModelId];
  let countOfSelectedSquares = countSelectedSquaresByModel(
    selectedSquares,
    modelPrefix
  );

  // Handle special case for 1.c model
  if (selectedModelId === "1.c") {
    if (selectedSquares["6.1.d"]) {
      countOfSelectedSquares += 1;
    }
    if (selectedSquares["6.1.e"]) {
      countOfSelectedSquares += 1;
    }
  }

  // Adjust count when squares are disabled
 

  //if (selectedModelId === "1.a" && selectedSquares["6.1.d"]) {
   // countOfSelectedSquares += 1;
  //}
  //if (selectedModelId === "1.a" && selectedSquares["6.1.e"]) {
  //  countOfSelectedSquares += 1;
  //}
  //if (selectedModelId === "1.a") {
    //countOfSelectedSquares = Math.max(0, countOfSelectedSquares - 1);
  //}
  if (dimensions.length > 700) {
    if (shedOptions.isGarageChecked) {
      const garageOptions = shedOptions.selectedGarageOption;
      if (garageOptions.includes("3.1.a") || garageOptions.includes("3.1.b")) {
        countOfSelectedSquares -= 4; // Subtract 4 squares if one garage option is selected
        if (
          garageOptions.includes("3.1.a") &&
          garageOptions.includes("3.1.b")
        ) {
          countOfSelectedSquares -= 4; // Additional subtract 4 squares if both garage options are selected (total 8)
        }
      }
    }
  } else {
    if (shedOptions.isGarageChecked) {
      const garageOptions = shedOptions.selectedGarageOption;
      if (garageOptions.includes("3.1.a") || garageOptions.includes("3.1.b")) {
        countOfSelectedSquares -= 3; // Subtract 3 squares if one garage option is selected
        if (
          garageOptions.includes("3.1.a") &&
          garageOptions.includes("3.1.b")
        ) {
          countOfSelectedSquares -= 3; // Additional subtract 3 squares if both garage options are selected (total 6)
        }
      }
    }
  }
  if (dimensions.length > 700) {
    if (
      shedOptions.selectedRoomOption === "3.2.a" ||
      shedOptions.selectedRoomOption === "3.2.c"
    ) {
      countOfSelectedSquares -= 4;
    } else if (shedOptions.selectedRoomOption === "3.2.b") {
      countOfSelectedSquares -= 2;
    }
  } else {
    if (
      shedOptions.selectedRoomOption === "3.2.a" ||
      shedOptions.selectedRoomOption === "3.2.c"
    ) {
      countOfSelectedSquares -= 3;
    } else if (shedOptions.selectedRoomOption === "3.2.b") {
      countOfSelectedSquares -= 2;
    }
  }
  //console.log(countOfSelectedSquares)
  return countOfSelectedSquares;
};
