import React from "react";
import ColorChoiceGrid from "./ColorChoiceGrid";
import "./ColorChoiceComponent.css";

const ColorChoiceComponent = () => {
  const row1Ids = ["4.1.a", "4.1.b", "4.1.c", "4.1.d", "4.1.e", "4.1.f"];
  const row2Ids = ["4.2.a", "4.2.b", "4.2.c", "4.2.d"];
  const row3Ids = ["4.3.a", "4.3.b", "4.3.c", "4.3.d", "4.3.e", "4.3.f"];

  const row1Colors = [
    "#394247",
    "#462e2a",
    "#87491f",
    "#a0a2a1",
    "#0e0d11",
    "#ebede8",
  ];
  const row2Colors = ["#394247", "#462e2a", "#a0a2a1", "#0e0d11"];
  const row3Colors = [
    "#394247",
    "#462e2a",
    "#87491f",
    "#a0a2a1",
    "#0e0d11",
    "#ebede8",
  ];

  const row1Names = [
    "Antracyt (RAL 7016)",
    "Brązowy (RAL 8017)",
    "Jasny Brąz (RAL 8003)",
    "Srebrny (RAL 9006)",
    "Czarny (RAL 9005)",
    "Biały (RAL 9003)",
  ];
  const row2Names = [
    "Antracyt (RAL 7016)",
    "Brązowy (RAL 8017)",
    "Srebrny (RAL 9006)",
    "Czarny (RAL 9005)",
  ];
  const row3Names = [
    "Antracyt (RAL 7016)",
    "Brązowy (RAL 8017)",
    "Jasny Brąz (RAL 8003)",
    "Srebrny (RAL 9006)",
    "Czarny (RAL 9005)",
    "Biały (RAL 9003)",
  ];

  return (
    <div className="colorwrapper">
    <div className="color-choice-container">
      <h3 className="header-text">Kolor słupów</h3>
      <ColorChoiceGrid
        ids={row1Ids}
        colors={row1Colors}
        names={row1Names}
        category="poleColor"
      />
      <div className="grid-wrapper">

      
      <h3 className="header-text">Kolor dachu</h3>
      <ColorChoiceGrid
        ids={row2Ids}
        colors={row2Colors}
        names={row2Names}
        category="roofColor"
      />
      </div>
      <div className="grid-wrapper">
      <h3 className="header-text">Kolor maskownicy</h3>
      <ColorChoiceGrid
        ids={row3Ids}
        colors={row3Colors}
        names={row3Names}
        category="facadeColor"
      />
      </div>
    </div>
    </div>
  );
};

export default ColorChoiceComponent;
