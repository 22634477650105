import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Styles specific to ShelterOptions component
const styles = StyleSheet.create({
  section: {
    padding: 2,
    backgroundColor: "#f9f9f9",
    border: "1px solid #e0e0e0",
  },
  heading: {
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 2,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: 1,
  },
  tableRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  tableHeader: {
    padding: 3,
    border: "1px solid #d0d0d0",
    backgroundColor: "#e0e0e0",
    fontWeight: "bold",
    width: "50%",
    fontSize: 7,
  },
  tableCell: {
    padding: 3,
    border: "1px solid #d0d0d0",
    width: "50%",
    fontSize: 7,
  },
});

const ShelterOptions = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.heading}>Opcje wiaty</Text>
    <View style={styles.table}>
      {/* Garage */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Garaż</Text>
        <Text style={styles.tableCell}>
          {data.garageChecked} {data.garageOption}
        </Text>
      </View>

      {/* Shed */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Pomieszczenie gospodarcze</Text>
        <Text style={styles.tableCell}>
          {data.shedChecked} {data.shedOption}
        </Text>
      </View>

      {/* Wall Type */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>
          Typ ściany (jeśli wybrano garaż lub pomieszczenie gospodarcze)
        </Text>
        <Text style={styles.tableCell}>{data.wallTypeChecked}</Text>
      </View>

      {/* Shed Position */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Położenie wiaty</Text>
        <Text style={styles.tableCell}>
          {data.shedPositionChecked} {data.shedPosition}
        </Text>
      </View>

      {/* Snow Zone */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Strefa śniegowa</Text>
        <Text style={styles.tableCell}>
          {data.snowZoneChecked}, Kod pocztowy: {data.postalCode}
        </Text>
      </View>

      {/* Photovoltaics Reinforcement */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Wzmocnienie pod fotowoltaikę</Text>
        <Text style={styles.tableCell}>
          {data.photovoltaicsReinforcementChecked}
        </Text>
      </View>

      {/* Montage */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Montaż</Text>
        <Text style={styles.tableCell}>{data.montageChecked}</Text>
      </View>

      {/* Maskownica Premium */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Maskownica</Text>
        <Text style={styles.tableCell}>{data.maskownicaPremiumChecked}</Text>
      </View>

      {/* Gutter */}
      <View style={styles.tableRow}>
        <Text style={styles.tableHeader}>Rynna</Text>
        <Text style={styles.tableCell}>{data.rynnaChecked}</Text>
      </View>
    </View>
  </View>
);

export default ShelterOptions;
