import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelColorChoiceRhombus from "./PanelColorChoiceRhombus";
import {
  selectColor,
  selectSelectedId,
} from "../../store/reducers/panelColorChoiceSlice";
import { selectSelectedId as selectPanelSelectedId } from "../../store/reducers/panelChoiceSlice";
import "./PanelColorChoiceRhombusGrid.css";

const PanelColorChoiceRhombusGrid = ({ ids, colors, colorNames }) => {
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const panelSelectedId = useSelector(selectPanelSelectedId);

  const handleSelect = (id) => {
    dispatch(selectColor(id));
  };

  const gridClass = `panel-colorgrid ${panelSelectedId === "5.a" ? "panel-colorgrid-special" : ""}`;

  return (
    <div className="flex flex-wrap">
      <div className={gridClass}>
        {ids.map((id, index) => (
          <div key={id} onClick={() => handleSelect(id)}>
            <PanelColorChoiceRhombus
              id={id}
              color={colors[index]} 
              selected={selectedId === id}
              isVisible={panelSelectedId !== "5.a" || id === "7.a"}
              name={colorNames[index]}  
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PanelColorChoiceRhombusGrid;
