import React from 'react';
import { Document } from '@react-pdf/renderer';
import PageOne from './DynamicPdf/PageOne';
import PageTwo from './DynamicPdf/PageTwo';

const ComprehensivePDFDocument = ({ data }) => (
  <Document>
    <PageOne data={data} />
    <PageTwo data={data} />
  </Document>
);

export default ComprehensivePDFDocument;