// translateShedOptions.js
export const translateIsGarageChecked = (isGarageChecked) => isGarageChecked ? "Tak," : "Nie";

export const translateIsRoomChecked = (isRoomChecked) => isRoomChecked ? "Tak," : "Nie";

export const translateSelectedGarageOption = (selectedGarageOptions) => {
  const hasLeft = selectedGarageOptions.includes('3.1.a');
  const hasRight = selectedGarageOptions.includes('3.1.b');

  if (hasLeft && hasRight) {
    return 'z prawej i z lewej';
  } else if (hasLeft) {
    return 'z lewej';
  } else if (hasRight) {
    return 'z prawej';
  } else {
    return ''; 
  }
};

export const translateSelectedRoomOption = (selectedRoomOption) => {
  switch (selectedRoomOption) {
    case '3.2.a': return 'z lewej';
    case '3.2.b': return 'z tyłu';
    case '3.2.c': return 'z prawej';
    default: return '';
  }
};

export const translateIsShedPositionChecked = (isShedPositionChecked) => isShedPositionChecked ? "Przy ścianie" : "Standard";

export const translateShedPosition = (shedPosition) => {
  if (shedPosition.includes('3.3.a')) return 'z prawej strony';
  if (shedPosition.includes('3.3.b')) return 'z lewej strony';
  if (shedPosition.includes('3.3.c')) return 'z tyłu';
  return '';
};

export const translateIsSnowZoneChecked = (isSnowZoneChecked) => isSnowZoneChecked ? "Tak" : "Brak";

export const translateIsMontageChecked = (isMontageChecked) => isMontageChecked ? "Tak" : "Brak";

export const translateisPhotovoltaicsReinforcementChecked = (isPhotovoltaicsReinforcementChecked) => isPhotovoltaicsReinforcementChecked ? "Tak" : "Nie";

export const translateIsGutterTypeChecked = (isGutterTypeChecked) => isGutterTypeChecked ? "Kwadratowa" : "Okrągła";

export const translateIsGrillTypeChecked = (isGrilleTypeChecked) => isGrilleTypeChecked ? "Premium" : "Standard";

export const translateIsWallTypeChecked = (isWallTypeChecked, isGarageChecked, isRoomChecked) => {
  if (!isGarageChecked && !isRoomChecked) {
    return "-";
  }
  return isWallTypeChecked ? "Fala" : "PIR";
};
