import React from "react";
import "./ColorChoiceRhombus.css";

const ColorChoiceRhombus = ({ id, color, selected, name }) => {
  const [boldPart, regularPart] = name.split(" (");
  const regularText = regularPart ? `(${regularPart}` : "";

  const textColorClass =
    name === "Biały (RAL 9003)" ? "black-text" : "white-text";

  return (
    <div
      className={`color-choice-rhombus-wrapper ${selected ? "selected" : ""}`}
      id={id}
    >
      <div className="color-choice-rhombus">
        <div className="color-block" style={{ backgroundColor: color }}></div>
        <div className={`hover-text ${textColorClass}`}>
          <span className="bold">{boldPart}</span>
          <span className="regular"> {regularText}</span>
        </div>
      </div>
    </div>
  );
};

export default ColorChoiceRhombus;
