import React, { useState } from 'react';
import './MobileSummaryButton.css';
import SummaryFormComponent from '../../Summary/SummaryFormComponent/SummaryFormComponent';
import { useConfiguratorContext } from '../../../ContextProvider'; // Updated import path and hook name

const MobileSummaryButton = ({ id = 'mobile-summary-button-id' }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { closeAll } = useConfiguratorContext();

  const handleOpenPopup = () => {
    if (closeAll) {
      closeAll();
    }
    setIsPopupOpen(true);
  };

  return (
    <>
      <button id={id} className="mobile-summary-button" onClick={handleOpenPopup}>
        <span className="mobile-summary-text">Podsumowanie</span>
      </button>
      {isPopupOpen && <SummaryFormComponent onClose={() => setIsPopupOpen(false)} />}
    </>
  );
};

export default MobileSummaryButton;
