import { createSlice } from '@reduxjs/toolkit';

export const formInfoSlice = createSlice({
  name: 'formInfo',
  initialState: {
    email: '',
    fullName: '',
    phone: '',
    postalCode: '',
    feedbackMessage: '',
    salesmanName: '', // Add salesman's name here
    salesmanPhoneNumber: '', // Add salesman's phone number here
    salesmanEmail: '', // Add salesman's email here
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    setFeedbackMessage: (state, action) => {
      state.feedbackMessage = action.payload;
    },
    setSalesmanName: (state, action) => {
      state.salesmanName = action.payload;
    },
    setSalesmanPhoneNumber: (state, action) => {  // New action for phone number
      state.salesmanPhoneNumber = action.payload;
    },
    setSalesmanEmail: (state, action) => {  // New action for email
      state.salesmanEmail = action.payload;
    },
  },
});

export const { 
  setEmail, 
  setFullName, 
  setPhone, 
  setPostalCode, 
  setFeedbackMessage, 
  setSalesmanName,
  setSalesmanPhoneNumber,  // Export the new action
  setSalesmanEmail,         // Export the new action
} = formInfoSlice.actions;

export const selectEmail = (state) => state.formInfo.email;
export const selectFullName = (state) => state.formInfo.fullName;
export const selectPhone = (state) => state.formInfo.phone;
export const selectPostalCode = (state) => state.formInfo.postalCode;
export const selectFeedbackMessage = (state) => state.formInfo.feedbackMessage;
export const selectSalesmanName = (state) => state.formInfo.salesmanName;
export const selectSalesmanPhoneNumber = (state) => state.formInfo.salesmanPhoneNumber;
export const selectSalesmanEmail = (state) => state.formInfo.salesmanEmail;

export default formInfoSlice.reducer;
