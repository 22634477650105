import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PanelColorChoiceRhombusGrid from "./PanelColorChoiceRhombusGrid";
import { selectSelectedId as selectPanelSelectedId } from "../../store/reducers/panelChoiceSlice";
import { resetPanelColorChoice } from "../../store/reducers/panelColorChoiceSlice";
import "./PanelColorChoiceGridsContainer.css";

const PanelColorChoiceGridsContainer = () => {
  const dispatch = useDispatch();
  const panelSelectedId = useSelector(selectPanelSelectedId);

  useEffect(() => {
    if (panelSelectedId === "5.a") {
      dispatch(resetPanelColorChoice());
    }
  }, [panelSelectedId, dispatch]);

  const ids = ["7.a", "7.b", "7.c", "7.d", "7.e", "7.f"];
  const colors = [
    "#394247", // Antracyt
    "#462e2a", // Brązowy
    "#87491f", // Jasny Brąz
    "#a0a2a1", // Srebrny
    "#0e0d11", // Czarny
    "#ebede8", // Biały
  ];

  const colorNames = [
    "Antracyt (RAL 7016)",
    "Brązowy (RAL 8017)",
    "Jasny Brąz (RAL 8003)",
    "Srebrny (RAL 9006)",
    "Czarny (RAL 9005)",
    "Biały (RAL 9003)",
  ];

  return (
    <div className="flex flex-wrap panel-color-choice-container">
      {panelSelectedId === "5.a" && (
        <div className="panel-color-disabled-container">
          <p className="panel-color-disabled-text">
            Panel falisty dostępny jedynie w kolorze antracytu
          </p>
        </div>
      )}
      <PanelColorChoiceRhombusGrid
        ids={ids}
        colors={colors}
        colorNames={colorNames}
        panelSelectedId={panelSelectedId}
      />
    </div>
  );
};

export default PanelColorChoiceGridsContainer;
