import React, { createContext, useContext, useState } from 'react';

const ConfiguratorContext = createContext();

export const ContextProvider = ({ children }) => {
  const [closeAll, setCloseAll] = useState(null);

  return (
    <ConfiguratorContext.Provider value={{ closeAll, setCloseAll }}>
      {children}
    </ConfiguratorContext.Provider>
  );
};

export const useConfiguratorContext = () => useContext(ConfiguratorContext);